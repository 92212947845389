import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import DeleteDialog from '@components/Dialog/DeleteDialog';
import DetailDialog from '@components/Dialog/DetailDialog';
import EditDialog from '@components/Dialog/EditDialog';
import EditPdcHire from '@components/HireCharts/EditPdcHire';
import HireChartDetails from '@components/HireCharts/HireChartDetails';
import PageTitle from '@components/PageTitle';

import { useReadExtras } from '../../hooks/extra';
import {
  useDeleteHireChart,
  useEditHireChart,
  useFilterHireChart,
} from '../../hooks/hirechart';
import {
  useAddPtoll,
  useDeletePtollByHid,
  useReadPtolls,
} from '../../hooks/ptoll';
import { IHireChart } from '../../types/hirechart';

const VehicleDrivers = () => {
  const [localhirechart, setLocalHirechart] = useState<IHireChart[]>([]);
  const [srlocalhirechart, setSRLocalHirechart] = useState<IHireChart[]>([]);
  const [hirechart, setHireChart] = useState<IHireChart | null>(null);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteHirechartMutate } = useDeleteHireChart();
  const { mutate: editHirechartMutate } = useEditHireChart();
  const { mutate: filterHireChartMutate, isPending: isLoading } =
    useFilterHireChart();
  const { mutate: useAddPtollMutate } = useAddPtoll();
  const { mutate: useDeletePtollByHidMutate } = useDeletePtollByHid();
  const { data: ptolls } = useReadPtolls();
  const { data: extraData = [] } = useReadExtras();

  useEffect(() => {
    document.title = 'Reports - nunes';
    handleSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localhirechart) {
      const hirechartsWithSr = localhirechart.map((hireItems, index) => ({
        ...hireItems,
        sr: index + 1,
      }));
      setSRLocalHirechart(hirechartsWithSr);
    }
  }, [localhirechart]);

  const getDefaultDateRange = () => {
    const today = new Date();

    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);

    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      1
    );

    return {
      startDate: firstDayOfMonth.toISOString().split('T')[0],
      endDate: lastDayOfMonth.toISOString().split('T')[0],
    };
  };

  const savedDateRange = (() => {
    const savedData = localStorage.getItem('VehicleDriverFilteredDateRange');
    return savedData ? JSON.parse(savedData) : getDefaultDateRange();
  })();

  const { handleSubmit, control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      startDate: savedDateRange.startDate,
      endDate: savedDateRange.endDate,
      company: '',
      hirechartType: [],
      searchBy: '',
      searchValue: '',
      driver: '',
    },
  });

  const watchedStartDate = watch('startDate');
  const watchedEndDate = watch('endDate');

  useEffect(() => {
    localStorage.setItem(
      'VehicleDriverFilteredDateRange',
      JSON.stringify({
        startDate: watchedStartDate,
        endDate: watchedEndDate,
      })
    );
  }, [watchedStartDate, watchedEndDate]);

  const formatDate = (dateStr: string) => {
    try {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = String(date.getFullYear()).slice(-2);
      return `${day}-${month}-${year}`;
    } catch (error) {
      console.error('Error parsing date:', error);
      return dateStr;
    }
  };

  const getELO = (item: any) => {
    let elo = '';
    if (item.early === '1') elo = 'E';
    if (item.early_morning === '1') elo = 'EM';
    if (item.late === '1') elo += ' L';
    if (item.onite === '1') elo += ' O';
    return elo.trim();
  };

  const handleDetails = (rowData: any) => {
    if (rowData) {
      setHireChart(rowData);
      setDrawerOpen(true);
    }
  };

  const handleEdit = (rowData: any) => {
    setHireChart(rowData);
    const selectedExtras = ptolls?.filter(
      (toll: any) => toll?.hid === rowData?.id
    );

    const extrasData = extraData?.map((item: any) => ({
      ...item,
      checked: !!selectedExtras?.filter((extra: any) => extra.eid === item.id)
        ?.length,
    }));
    setDefaultvalue({
      id: rowData?.id,
      date: rowData?.date,
      company: rowData?.company,
      ac: rowData?.ac,
      vtype: rowData?.vtype,
      ocode: rowData?.ocode,
      vno: rowData?.vno,
      driver: rowData?.driver,
      cleaner: rowData?.cleaner,
      hiretype: rowData?.hiretype,
      client: rowData?.client,
      subagent: rowData?.subagent,
      repveh: rowData?.repveh,
      rvocode: rowData?.rvocode,
      okm: rowData?.okm,
      ckm: rowData?.ckm,
      tkm: rowData?.tkm,
      akm: rowData?.akm,
      ttkm: rowData?.ttkm,
      bill: rowData?.bill,
      billno: rowData?.billno,
      extra_toll: rowData?.extra_toll,
      extras: extrasData,
      toll: rowData?.toll,
      tollc: rowData?.tollc,
      break: rowData?.break,
      remark: rowData?.remark,
      particulars: rowData?.particulars,
      particulars_type: rowData?.particulars_type,
      early: rowData?.early === '1',
      early_morning: rowData?.early_morning === '1',
      late: rowData?.late === '1',
      onite: rowData?.onite === '1',
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setHireChart(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (hirechart && action && action?.action) {
      const extrasTotal = action?.formData?.extras
        ?.filter((d: any) => d?.checked === true)
        ?.reduce((acc: any, cum: any) => acc + (cum?.rate ? +cum.rate : 0), 0);

      const toll =
        (extrasTotal || 0) +
        (action.formData.extra_toll ? +action.formData.extra_toll : 0);

      const formData = {
        ...action.formData,
        id: +hirechart.id,
        toll,
      };

      const extras = formData.extras?.filter((ex: any) => ex?.checked === true);
      delete formData.extras;

      editHirechartMutate(formData, {
        onSuccess: () => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useDeletePtollByHidMutate(
            {
              hid: +hirechart.id,
            },
            {
              onSuccess: () => {
                const addPtollArr: any = [];

                extras?.forEach((ex: any) => {
                  if (ex.id) {
                    addPtollArr.push({
                      hid: +hirechart.id,
                      eid: ex.id,
                      rate: ex.rate,
                    });
                  }
                });
                // eslint-disable-next-line
                useAddPtollMutate(addPtollArr, {
                  onSuccess: () => {
                    enqueueSnackbar('Content Updated', { variant: 'info' });
                    window.location.reload();
                  },
                });
              },
              onError: () => {
                enqueueSnackbar('Failed to Update Content', {
                  variant: 'error',
                });
              },
            }
          );
          setEditDialogOpen(false);
        },

        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      });
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (hirechart) {
      deleteHirechartMutate(
        { id: +hirechart.id },
        {
          onSuccess: () => {
            setLocalHirechart(prevHirechart =>
              prevHirechart.filter(c => c.id !== hirechart.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleSearch = handleSubmit((formData: any) => {
    const filterCriteria = {
      ...(formData?.startDate && { startDate: formData?.startDate }),
      ...(formData?.endDate && { endDate: formData?.endDate }),
      ...(formData?.driver && { driver: formData?.driver }),
      driverPage: true,
    };

    filterHireChartMutate(filterCriteria, {
      onSuccess: (filteredData: any) => {
        if (filteredData?.data) {
          const processedData = filteredData?.data?.map((item: any) => ({
            ...item,
            elo: getELO(item),
          }));
          setLocalHirechart(processedData);
        }
      },
      onError: () => {
        enqueueSnackbar('Failed to fetch filtered data', { variant: 'error' });
      },
    });
  });

  const columns: Column<IHireChart>[] = [
    {
      title: 'S.No',
      field: 'sr',
      sorting: true,
      width: '5%',
    },
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: rowData => formatDate(rowData.date),
    },
    {
      title: 'Company',
      field: 'company',
    },
    {
      title: 'Veh Type',
      field: 'vtype',
    },
    {
      title: 'Own Code',
      field: 'ocode',
    },
    {
      title: 'Veh No.',
      field: 'vno',
    },
    {
      title: 'Driver',
      field: 'driver',
    },
    {
      title: 'Rep Veh',
      field: 'repveh',
    },
    {
      title: 'Rep Own Code',
      field: 'rvocode',
    },
    {
      title: 'Particulars',
      render: rowData => `${rowData.particulars} ${rowData.particulars_type}`,
    },
    {
      title: 'Hire Type',
      field: 'hiretype',
    },
    {
      title: 'Sub Agent',
      field: 'subagent',
    },
    {
      title: 'Client',
      field: 'client',
    },
    {
      title: 'E/EM/L/O',
      field: 'elo',
    },
    {
      title: 'Toll',
      field: 'toll',
    },
    {
      title: 'Bill No.',
      field: 'billno',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="Vehicle Drivers - Report" />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Accordion style={{ padding: '10px' }}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: 'primary.main',
                    '&.Mui-expanded': { transform: 'rotate(180deg)' },
                  }}
                />
              }
              aria-controls="filter-content"
              id="filter-header"
              sx={{
                '& .MuiAccordionSummary-content': {
                  alignItems: 'center',
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                  color: 'primary.main',
                },
              }}
            >
              <Typography variant="h6">Filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form onSubmit={handleSearch}>
                <Grid container spacing={2} style={{ padding: '10px' }}>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="startDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="From Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="To Date"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          InputLabelProps={{ shrink: true }}
                          type="date"
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4}>
                    <Controller
                      control={control}
                      name="driver"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          placeholder="Vehicle driver"
                          variant="outlined"
                          size="medium"
                          fullWidth
                          sx={{ width: '100%' }}
                          type="text"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </AccordionDetails>
          </Accordion>

          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={srlocalhirechart || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: false,
                search: false,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
                Toolbar: () => <div style={{ margin: '10px' }}> </div>,
              }}
              onRowClick={(_, rowData) => handleDetails(rowData)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <DetailDialog
        open={drawerOpen}
        handleCloseDrawer={() => setDrawerOpen(false)}
        ContentComponent={HireChartDetails}
        data={hirechart}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditPdcHire}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default VehicleDrivers;
