import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddContact from '@components/Contact/AddContact';
import ContactDetail from '@components/Contact/ContactDetail';
import EditContact from '@components/Contact/EditContact';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import DetailDialog from '@components/Dialog/DetailDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddContact,
  useDeleteContact,
  useEditContact,
  useReadContacts,
} from '../../hooks/contact';
import { IContact } from '../../types/contact';

const Contact = () => {
  const { data: contacts, isLoading, refetch } = useReadContacts();

  const [localContacts, setLocalContacts] = useState<IContact[]>([]);
  const [contact, setContact] = useState<IContact | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const { mutate: deleteContactMutate } = useDeleteContact();
  const { mutate: addContactMutate } = useAddContact();
  const { mutate: editContactMutate } = useEditContact();

  useEffect(() => {
    document.title = 'Contact - nunes';
    if (contacts) {
      const contactsWithSr = contacts.map((contactItems, index) => ({
        ...contactItems,
        sr: index + 1,
      }));
      setLocalContacts(contactsWithSr);
    }
  }, [contacts]);

  const handleDetails = (rowData: IContact | undefined) => {
    if (rowData) {
      setContact(rowData);
      setDrawerOpen(true);
    }
  };

  const handleEdit = (rowData: any) => {
    setContact(rowData);
    setDefaultvalue({
      cname: rowData?.cname,
      phone: rowData?.phone,
      address: rowData?.address,
      cpa_fname: rowData?.cpa_fname,
      cpa_lname: rowData?.cpa_lname,
      cpa_phone1: rowData?.cpa_phone1,
      cpa_phone2: rowData?.cpa_phone2,
      cpo_fname: rowData?.cpo_fname,
      cpo_lname: rowData?.cpo_lname,
      cpo_phone1: rowData?.cpo_phone1,
      cpo_phone2: rowData?.cpo_phone2,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setContact(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (contact && action && action?.action) {
      editContactMutate(
        { ...action?.formData, id: +contact.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Contact Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (contact) {
      deleteContactMutate(
        { id: +contact.id },
        {
          onSuccess: () => {
            setLocalContacts(prevContacts =>
              prevContacts.filter(c => c.id !== contact.id)
            );
            enqueueSnackbar('Contact Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addContactMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar('Failed to Add Content', { variant: 'error' });
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<IContact>[] = [
    {
      title: 'S.No',
      field: 'sr',
      sorting: true,
      width: '5%',
    },
    {
      title: 'Company',
      field: 'cname',
      render: rowData => `${rowData?.cname}`,
    },
    {
      title: 'Address',
      field: 'address',
      render: rowData => `${rowData?.address} `,
    },
    { title: 'Phone', field: 'phone', render: rowData => `${rowData?.phone}` },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Contacts"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Contact
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localContacts || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              onRowClick={(_, rowData) => handleDetails(rowData)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddContact}
      />

      <DetailDialog
        open={drawerOpen}
        handleCloseDrawer={() => setDrawerOpen(false)}
        ContentComponent={ContactDetail}
        data={contact}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditContact}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default Contact;
