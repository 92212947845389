import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import { Grid } from '@mui/material';

import AddNewHire from '@components/HireCharts/AddNewHire';
import PageTitle from '@components/PageTitle';

import { useAddHireChart } from '../../hooks/hirechart';
import { useAddPtoll, useDeletePtollByHid } from '../../hooks/ptoll';

const HireChart = () => {
  const methods = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: addHirechartMutate } = useAddHireChart();
  const { mutate: useAddPtollMutate } = useAddPtoll();
  const { mutate: useDeletePtollByHidMutate } = useDeletePtollByHid();

  const handleFormSubmit = (formData: any) => {
    const extrasTotal = formData?.extras
      ?.filter((ex: any) => ex?.checked === true)
      .reduce((acc: any, cum: any) => acc + (cum?.rate ? +cum.rate : 0), 0);

    const toll =
      (extrasTotal || 0) + +(formData.extra_toll ? formData.extra_toll : 0);
    formData.toll = toll;
    const extras = formData?.extras?.filter((ex: any) => ex?.checked === true);

    delete formData.extras;

    addHirechartMutate(formData, {
      onSuccess: res => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useDeletePtollByHidMutate(
          {
            hid: res?.data?.id,
          },
          {
            onSuccess: () => {
              const addPtollArr: any = [];
              extras?.map((ex: any) => {
                if (ex.id) {
                  addPtollArr.push({
                    hid: res?.data?.id,
                    eid: ex.id,
                    rate: ex.rate,
                  });
                }
                return null;
              });
              // eslint-disable-next-line
              useAddPtollMutate(addPtollArr, {
                onSuccess: () => {
                  enqueueSnackbar('Content Updated', { variant: 'info' });
                },
              });
            },
            onError: () => {
              enqueueSnackbar('Failed to Update Content', { variant: 'error' });
            },
          }
        );

        // // eslint-disable-next-line
        // useAddPtollMutate(addPtollArr, {
        //   onSuccess: () => {
        //     enqueueSnackbar('Content Updated', { variant: 'info' });
        //   },
        // });
      },
      onError: () => {
        enqueueSnackbar('Failed to add hire', { variant: 'error' });
      },
    });
  };

  return (
    <FormProvider {...methods}>
      <Grid
        container
        direction="column"
        spacing={1}
        wrap="nowrap"
        sx={{
          height: '100%',
          padding: { xs: '0.5rem', sm: '1rem' },
        }}
      >
        <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
          <PageTitle text="New Hire" />
        </Grid>

        <Grid item xs={12}>
          <AddNewHire onSubmit={methods.handleSubmit(handleFormSubmit)} />
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default HireChart;
