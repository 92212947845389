/* eslint-disable no-useless-escape */
import { useEffect, useRef, useState } from 'react';

import parse from 'html-react-parser';
import { useSnackbar } from 'notistack';
import { CSVLink } from 'react-csv';
import { useReactToPrint } from 'react-to-print';
import { ToWords } from 'to-words';

import { Button, Grid, Paper } from '@mui/material';

// import DeleteDialog from '@components/Dialog/DeleteDialog';
import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import {
  useAddBillReg,
  useGetBillRegByLastCreated,
  useUpdateBillByHid,
} from '../../hooks/bill';
import { useReadCompanies } from '../../hooks/company';
import { useReadBillDates } from '../../hooks/controlpanel/billdates';
import { useUpdateHireChart } from '../../hooks/hirechart';

// import { useSnackbar } from 'notistack';

const PendingBills = () => {
  // const navigate = useNavigate();
  // const { id } = useParams();
  // const { enqueueSnackbar } = useSnackbar();

  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        name: 'Rupee',
        plural: 'Rupees',
        symbol: '₹',
        fractionalUnit: {
          name: 'Paisa',
          plural: 'Paise',
          symbol: '',
        },
      },
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const [billNo, setBillNo] = useState('');
  const [rawHTML, setRawHTML] = useState('');
  const [grandTotalState, setGrandTotalState] = useState(0);
  const [csvData, setCsvData] = useState<any>([]);
  const [showHTML, setShowHTML] = useState(false);
  const [companyData, setCompanyData] = useState<any>(null);

  // const { data } = useReadHireChartMulti({ id } as any);
  const hirechartsData = JSON.parse(
    localStorage.getItem('hirecharts') as string
  );
  const { data: companies } = useReadCompanies();
  const { data: billDates } = useReadBillDates();

  // const { data: billRows } = useReadBills();
  const { mutate: addBillRegMutate } = useAddBillReg();
  const { data: lastCreatedBillReg, refetch } = useGetBillRegByLastCreated();
  const { mutate: updateHirechartMutate } = useUpdateHireChart();
  const { mutate: updateBillByHidMutate } = useUpdateBillByHid();

  const printRef = useRef<HTMLDivElement>(null);

  const print = useReactToPrint({
    content: () => printRef.current,
  });

  const handlePrint = () => {
    console.log('333', hirechartsData);
    if (
      hirechartsData &&
      hirechartsData.length &&
      (hirechartsData[0].hirechart.billno === '0' ||
        hirechartsData[0].hirechart.billno === '')
    ) {
      refetch();
      let billCount = 101;
      if (lastCreatedBillReg) {
        billCount = Number(lastCreatedBillReg.id) + 1;
      }
      const currentYear = new Date().getFullYear().toString().slice(-2);
      const lastYear = Number(currentYear) - 1;
      const nextYear = Number(currentYear) + 1;
      let billNumber;
      if (new Date().getMonth() > 2) {
        billNumber = `JPN${lastYear}${currentYear}/${billCount}`;
      } else {
        billNumber = `JPN${currentYear}${nextYear}/${billCount}`;
      }
      hirechartsData[0].billNo = billNumber;

      addBillRegMutate(
        {
          id: billCount,
          date:
            billDates && billDates.length ? billDates[0].billdate : new Date(),
          billnum: billNumber,
          type: '1',
          total: grandTotalState,
          company: companyData?.name,
        },
        {
          onSuccess: () => {
            hirechartsData.map((h: any) => {
              if (billDates && billDates.length) {
                updateHirechartMutate({
                  id: h.id,
                  billno: billCount,
                  date: billDates[0].billdate,
                });
              } else {
                updateHirechartMutate({
                  id: h.id,
                  billno: billCount,
                });
              }

              return null;
            });
            hirechartsData.map((h: any) => {
              updateBillByHidMutate({
                hid: h.id,
                billed: 1,
              });

              return null;
            });

            setBillNo(billNo);
            print();
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', {
              variant: 'error',
            });
          },
        }
      );
    } else {
      setBillNo(billNo);
      // setShowBillNo(true);
      print();
    }
  };

  const handleExport = (_: any, done: any) => {
    hirechartsData.map((h: any) => {
      updateHirechartMutate({
        id: h.id,
        client: 'EX',
        bill: 0,
      });

      return null;
    });
    done(true);
  };

  useEffect(() => {
    if (hirechartsData && hirechartsData.length && companies) {
      const firstRow = hirechartsData[0].hirechart;
      const c = companies.find(cp => cp.name === firstRow.company);
      setCompanyData(c);
      if (!companyData) return;
      let minText = 'Min. Chrgs';
      if (companyData?.mintype === '1') {
        minText = '8 HRs / 100 KMs';
      }
      if (companyData?.mintype === '2') {
        minText = '8 HRs / 80 KMs';
      }
      const gstType = String(companyData?.gsttype);
      const gst = String(companyData?.gst);

      let rawHTMLString = ``;
      if (gst === '5') {
        rawHTMLString += `<p style="padding-top:5px;padding-bottom:5px;text-align:left;"><strong>Hire / Rental services of passenger vehicles, with or without operators SAC – 996601. (Under Sr. No. 10(i) of Noti. 11/2017 & 31/2017)</strong></p>`;
      } else if (gst === '18' || gst === '12') {
        rawHTMLString += `<p style="padding-top:5px;padding-bottom:5px;text-align:left;"><strong>Hire / Rental services of passenger vehicles, with or without operators SAC – 996601. (Under Sr. No. 10(ii) of Noti. 11/2017)</strong></p>`;
      } else if (gst === '0' && gstType !== '3') {
        rawHTMLString += `<p style="padding-top:5px;padding-bottom:5px;text-align:left;"><strong>Hire / Rental services of transport vehicles, with operators SAC – 996601. (Under Sr. No. 10(i) of Noti. 11/2017 & 31/2017)  </strong></p>`;
      } else if (gstType === '3') {
        rawHTMLString += `<p style="padding-top:5px;padding-bottom:5px;text-align:left;"><strong>Transport of passengers under contract carriage other than motor cab, exempt services provided to educational institution SAC - 996419 / 996413.</strong></p>`;
      }
      rawHTMLString += `
                  <p><strong>Date: </strong>${formatDate(firstRow?.date) || ''}
                  <p><strong>To: </strong>${companyData?.name || ''}</p>                
                  <p><strong>Address: </strong>${companyData?.address || ''}</p>
                  <p><strong>GST Number: </strong>${companyData?.gstno || ''}</p>
                  <p><strong>PAN Number: </strong>${companyData?.panno || ''}</p>
                  <p><strong>Sub Agent: </strong> ${firstRow?.subagent || ''}</p>
                  <p><strong>REF: </strong>${firstRow?.client || ''}</p>
                  <p><strong>Bill No: </strong>${billNo || ''}</p>
                  <table id="table-h" border="1" cellspacing="0" cellpadding="3" width="100%" style="font-size:11px;">
                  <tr valign="top">
                  <td width="55px" align="center"><strong>Datesss</strong></td>
                  <td width="65px" align="center"><strong>Veh Type</strong></td>
                  <td width="35px" align="center"><strong>Veh No.</strong></td>
                  <td><strong>Particulars</strong></td>
                  <td width="40px" align="center"><strong>${minText}</strong></td>
                  <td width="25px" align="center"><strong>Extra KMs</strong></td>
                  <td width="25px" align="center"><strong>Rate</strong></td>
                  <td width="30px" align="center"><strong>Total</strong></td>
                  <td width="25px" align="center"><strong>Extra HRs</strong></td>
                  <td width="25px" align="center"><strong>Wait. Chgs</strong></td>
                  <td width="25px" align="center"><strong>Total</strong></td>
                  <td width="25px" align="center"><strong>Early</strong></td>
                  <td width="25px" align="center"><strong>Early Morning</strong></td>
                  <td width="20px" align="center"><strong>Late</strong></td>
                  <td width="20px" align="center"><strong>O. Nite</strong></td>
                  <td width="20px" align="center"><strong>Extra</strong></td>
                  <td width="75px" align="right"><strong>Total</strong></td>
                  </tr>
                  `;

      let total = 0;
      let discount = 0;
      hirechartsData?.map((data: any) => {
        const billRow = data.bill;
        const row = data.hirechart;
        rawHTMLString += `<tr valign=\"top\"><td align=\"center\">${formatDate(row?.date)}</td><td align=\"center\">${row?.vtype}</td><td align=\"center\">${row?.vno}</td><td align="start">${row?.particulars || ''}/${row?.particulars_type || ''}</td><td align=\"center\">${billRow?.rate || 0}</td><td align=\"center\">${billRow?.xkms || 0}</td><td align=\"center\">${billRow?.kms || 0}</td><td align=\"center\">${billRow?.tot1 || 0}</td><td align=\"center\">${billRow?.xhrs || 0}</td><td align=\"center\">${billRow?.wchr || 0}</td><td align=\"center\">${billRow?.tot2 || 0}</td><td align=\"center\">${billRow?.early || 0}</td><td align=\"center\">${billRow?.early_morning || 0}</td><td align=\"center\">${billRow?.late || 0}</td><td align=\"center\">${billRow?.onite || 0}</td><td align=\"center\">${billRow?.toll || 0}</td><td align=\"right\">${billRow?.tot3 || 0}</td></tr>"`;
        total += Number(billRow?.tot3 || 0);
        discount = Number(row?.discount || 0);

        return null;
      });

      const netTaxable = total - discount;
      const gstRateDiff = 0;
      const gstValue = Number(companyData?.gst) || 0;
      const gstAmount = netTaxable * (gstValue / 100);
      const grandTotal = netTaxable + gstAmount;
      setGrandTotalState(grandTotal);
      // eslint-disable-next-line
      rawHTMLString += `<tr><td colspan="11">&nbsp;</td><td colspan="5">&nbsp;</td><td>&nbsp;</td></tr>
                <tr>
                
				<tr>
                	<td colspan="11" rowspan="2">`;

      if (gstType === '1') {
        rawHTMLString += `
      <table border="1" cellspacing="0" cellpadding="3" width="100%" style="font-size:11px;">
                        <tr>
                        <td width="10%" style="text-align:center; font-weight:bold;">GST Taxable (Amt)</td>
                        <td width="10%" style="text-align:center; font-weight:bold;">SGST Tax </td>
                        <td width="10%" style="text-align:center; font-weight:bold;">CGST Tax </td>
                        </tr>
                        <tr>
                        <td style="text-align:center;">${netTaxable}</td>
                        <td style="text-align:center;">${gstRateDiff}</td>
                        <td style="text-align:center;">${gstRateDiff}</td>
                        </tr>
                    </table>
      `;
      }

      if (gstType === '2') {
        rawHTMLString += `<table border="1" cellspacing="0" cellpadding="3" width="100%" style="font-size:11px;">
                		<tr>
                        <td width="10%" style="text-align:center; font-weight:bold;">Taxable (Amt)</td>
                        <td width="10%" style="text-align:center; font-weight:bold;">IGST Tax <? print $gst ?>%</td>
                        </tr>
                        <tr>
                        <td style="text-align:center;">${netTaxable}</td>
                        <td style="text-align:center;">${gstAmount}</td>
                        </tr>
                	</table> 
        `;
      }

      rawHTMLString += `
      <td colspan="5"><strong>Total (Rs.)</strong></td>
                		<td  align="right">${total}</td>
               	   </tr>
				                
					<tr>
                   
                    	<td colspan="5"><strong>Less (Rs.)</strong></td>
                        <td align="right">${discount}</td>
                  	</tr>
      `;

      if (gstType !== '3') {
        rawHTMLString += `
        <tr>
                    <td colspan="11"></td>
                    	<td colspan="5"><strong>Net Taxable (Rs.)</strong></td>
                        <td align="right">${netTaxable}</td>
                   	</tr>
        `;
      }

      if (gstType === '2') {
        rawHTMLString += `
        <tr>
                    <td colspan="11"></td>
                    	<td colspan="5"><strong>Add IGST @ ${gstValue} % (Rs.)</strong></td>
                        <td align="right">${gstAmount}</td>
                    </tr>
        `;
      }

      if (gstType === '1') {
        rawHTMLString += `
        <tr>
                    <td colspan="11"></td>
                    	<td colspan="5"><strong>Add GST @ ${gstValue} % (Rs.)</strong></td>
                        <td align="right">${gstAmount}</td>
                    </tr>
        `;
      }

      rawHTMLString += `
      <tr>
                  <td colspan="11"><strong>${toWords.convert(grandTotal)}</strong></td>
                  <td colspan="5"><strong>G. Total (Rs.)</strong></td>
                  <td align="right"><strong>&nbsp${grandTotal}&nbsp;</strong></td>
            </tr></table>`;

      if (gst === '0' && gstType !== '3') {
        rawHTMLString += `
          <p style="padding-top:15px;padding-bottom:5px;text-align:left;font-size:16px;"><strong>5% Goods &amp; Services Tax payable by Service Recipient under Reverse Charge Mechanism ${netTaxable * 0.05}/-</strong></p>
        `;
      }

      setRawHTML(rawHTMLString);
      setShowHTML(true);

      const csvDataToAdd = [
        [],
        ['Date', formatDate(firstRow?.date) || ''],
        ['To', companyData?.name || ''],
        ['Reg', firstRow?.client || ''],
        [],
        [],
        [
          'Date',
          'AC/NAC',
          'Veh Type',
          'Veh No',
          'Particulars',
          minText,
          'Extra KM',
          'Rate',
          'Total',
          'Extra Hrs',
          'Wait Ch',
          'Total',
          'Early',
          'Early Morning',
          'Late',
          'Onite',
          'Extra',
          'Total',
        ],
      ];
      hirechartsData?.map((data: any) => {
        const billRow = data.bill;
        const row = data.hirechart;
        csvDataToAdd.push([
          formatDate(row?.date),
          row?.ac,
          row?.vtype,
          row?.vno,
          `${row?.particulars || ''}/${row?.particulars_type || ''}`,
          billRow?.rate,
          billRow?.xkms,
          billRow?.kms,
          billRow?.tot1,
          billRow?.xhrs,
          billRow?.wchr,
          billRow?.tot2,
          billRow?.early,
          billRow?.early_morning,
          billRow?.late,
          billRow?.onite,
          billRow?.toll,
          billRow?.tot3,
        ]);

        return null;
      });
      csvDataToAdd.push([]);
      csvDataToAdd.push([]);
      csvDataToAdd.push([
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'Total',
        total,
      ]);
      csvDataToAdd.push([
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'Less',
        discount,
      ]);
      if (gstType !== '3') {
        csvDataToAdd.push([
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          'Net Taxable',
          netTaxable,
        ]);

        if (gstType === '2') {
          csvDataToAdd.push([
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            `Add IGST @ ${companyData?.gst}`,
            gstAmount,
          ]);
        }
        if (gstType === '1') {
          csvDataToAdd.push([
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            `Add GST @ ${companyData?.gst}`,
            gstAmount,
          ]);
        }
      }
      csvDataToAdd.push([
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        '',
        'G. Total',
        grandTotal,
      ]);
      setCsvData(csvDataToAdd);
    }
    // eslint-disable-next-line
  }, [companies, companyData]);

  useEffect(() => {
    const a = document.getElementById('table-h');
    const b = a?.previousSibling;
    b?.remove();
  }, [rawHTML]);

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="New Bill" />
      </Grid>
      <Grid item xs={2} sm={1} alignSelf="flex-end" py={2}>
        <Button
          style={{ paddingLeft: '58px', paddingRight: '58px' }}
          variant="contained"
          color="primary"
          onClick={handlePrint}
        >
          Print
        </Button>
      </Grid>
      <Grid item xs={2} sm={1} alignSelf="flex-end" py={2}>
        {companyData && (
          <CSVLink
            filename={`bill_${companyData?.name}.csv`}
            data={csvData}
            asyncOnClick
            onClick={(event, done) => handleExport(event, done)}
          >
            <Button variant="contained" color="primary">
              Download CSV
            </Button>
          </CSVLink>
        )}
      </Grid>
      <Grid ref={printRef} item xs container spacing={2}>
        <Paper
          elevation={2}
          sx={{
            width: '100%',
            margin: '1rem 3rem',
            padding: '8rem 0 9rem 0',
          }}
        >
          <Grid item xs={12} mx={4} my={4}>
            {/* eslint-disable-next-line */}
            {showHTML && parse(rawHTML)}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PendingBills;
