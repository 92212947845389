import { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { useReadDrivers } from '../../hooks/controlpanel/drivers';
import { useReadHotels } from '../../hooks/controlpanel/hotels';
import {
  useAddNCompany,
  useReadNCompanys,
} from '../../hooks/controlpanel/newcompanies';
import {
  useAddOwnerCode,
  useReadOwnerCodes,
} from '../../hooks/controlpanel/ownercode';
import {
  useAddVehicleNumber,
  useReadVehicleNumbers,
} from '../../hooks/controlpanel/vehiclenumber';
import {
  useAddVehicleType,
  useReadVehicleTypes,
} from '../../hooks/controlpanel/vehicletype';
import { useReadExtras } from '../../hooks/extra';
import SelectionDropdown from './SelectionDropdown';

const AddNewHire = ({ onSubmit }: any) => {
  const { control, register, setValue, handleSubmit, reset } = useFormContext();

  const { data: hotels, refetch: refetchHotels } = useReadHotels();

  const { data: companyList = [], refetch: refetchCompanies } =
    useReadNCompanys();
  const { data: vtypeList = [], refetch: refetchVTypes } =
    useReadVehicleTypes();
  const { data: vnumbers = [], refetch: refetchVNumbers } =
    useReadVehicleNumbers();
  const { data: ocodeList = [], refetch: refetchOCodes } = useReadOwnerCodes();
  const { data: drivers = [], refetch: refetchDrivers } = useReadDrivers();

  const { mutate: addNcompanyMutate } = useAddNCompany();
  const { mutate: addVehicletypeMutate } = useAddVehicleType();
  const { mutate: addVehiclenoMutate } = useAddVehicleNumber();
  const { mutate: addOcodeMutate } = useAddOwnerCode();

  const [selectedValue, setSelectedValue] = useState<any>(null);

  const { data: extraData = [] } = useReadExtras();

  const hireTypeList = ['ARR', 'A/S', 'D/A', 'DEP', 'DISP', 'S/D', 'S/S'];

  const openKM = useWatch({
    name: 'okm',
    control,
  });
  const closeKM = useWatch({
    name: 'ckm',
    control,
  });
  const totalKM = useWatch({
    name: 'tkm',
    control,
  });
  const additionalKM = useWatch({
    name: 'akm',
    control,
  });

  useEffect(() => {
    if (openKM && closeKM) {
      setValue('tkm', Number(closeKM) - Number(openKM));
    }
  }, [openKM, closeKM, setValue]);

  useEffect(() => {
    if (totalKM && additionalKM) {
      setValue('ttkm', Number(totalKM) + Number(additionalKM));
    }
  }, [totalKM, additionalKM, setValue]);

  useEffect(() => {
    if (selectedValue && !selectedValue.id) {
      const company = {
        name: selectedValue.name || '',
        info: '',
      };
      addNcompanyMutate(company, {
        onSuccess: () => {
          refetchCompanies();
        },
        onError: () => {
          enqueueSnackbar('Failed to Add content', { variant: 'error' });
        },
      });

      const vtype = {
        type: selectedValue.type || '',
        info: '',
      };
      addVehicletypeMutate(vtype, {
        onSuccess: () => {
          refetchVTypes();
        },
        onError: () => {
          enqueueSnackbar('Failed to Add content', { variant: 'error' });
        },
      });

      const vno = {
        num: selectedValue.number || '',
        info: '',
      };
      addVehiclenoMutate(vno, {
        onSuccess: () => {
          refetchVNumbers();
        },
        onError: () => {
          enqueueSnackbar('Failed to Add content', { variant: 'error' });
        },
      });

      const ocode = {
        code: selectedValue.code || '',
        info: '',
        mobile: '',
        phone: '',
      };
      addOcodeMutate(ocode, {
        onSuccess: () => {
          refetchOCodes();
        },
        onError: () => {
          enqueueSnackbar('Failed to Add content', {
            variant: 'error',
          });
        },
      });
    }
  }, [
    selectedValue,
    addOcodeMutate,
    refetchOCodes,
    refetchCompanies,
    refetchVTypes,
    addNcompanyMutate,
    addVehicletypeMutate,
    addVehiclenoMutate,
    refetchVNumbers,
    refetchDrivers,
    refetchHotels,
    drivers,
  ]);

  const driverList = drivers.filter((driver: any) => driver.role === 'Driver');
  const cleanerList = drivers.filter(
    (driver: any) => driver.role === 'Cleaner'
  );

  const defaultValues = {
    date: '',
    hiretype: '',
    clientname: '',
    subagent: '',
    repveh: '',
    rvocode: '',
    okm: '',
    ckm: '',
    tkm: '',
    akm: '',
    ttkm: '',
    extra_toll: '',
    tollc: '',
    bill: '',
    billno: '',
    break: '',
    particulars_type: '',
    remark: '',
    early: false,
    early_morning: false,
    late: false,
    onite: false,
  };

  const handleReset = () => {
    reset(defaultValues);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="content">
        <div className="content-inner">
          <Card style={{ padding: '10px' }}>
            <Grid
              container
              spacing={2}
              style={{ paddingBottom: '10px', marginTop: '10px' }}
            >
              <Grid item xs={12} sm={6}>
                <Controller
                  name="date"
                  control={control}
                  defaultValue={defaultValues.date}
                  rules={{ required: 'Date is required' }}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      label="Date"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectionDropdown
                  name="company"
                  options={companyList}
                  label="Company"
                  renderLabel={(name: any) => name?.name}
                  displayLabel="name"
                  setSelectedValue={(data: any) => setSelectedValue(data)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectionDropdown
                  name="vtype"
                  options={vtypeList}
                  label="Vehicle Type"
                  renderLabel={(type: any) => type?.type}
                  displayLabel="type"
                  setSelectedValue={(data: any) => setSelectedValue(data)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectionDropdown
                  name="vno"
                  options={vnumbers}
                  label="Vehicle no."
                  renderLabel={(num: any) => num?.number}
                  displayLabel="number"
                  setSelectedValue={(data: any) => setSelectedValue(data)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectionDropdown
                  name="ocode"
                  options={ocodeList}
                  label="Own Code"
                  renderLabel={(ocode: any) => ocode?.code}
                  displayLabel="code"
                  setSelectedValue={(data: any) => setSelectedValue(data)}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="driver"
                  control={control}
                  render={({ field }) => {
                    const currentValue = field.value
                      ? driverList.find(
                          driver => driver.name === field.value
                        ) || null
                      : null;

                    return (
                      <Autocomplete
                        {...field}
                        disablePortal
                        options={driverList.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )}
                        getOptionLabel={option => option.name}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? newValue.name : '');
                        }}
                        value={currentValue}
                        renderInput={params => (
                          <TextField {...params} label="Drivers" />
                        )}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="cleaner"
                  control={control}
                  render={({ field }) => {
                    const currentValue = field.value || '';

                    return (
                      <Autocomplete
                        {...field}
                        disablePortal
                        options={cleanerList.sort((a, b) =>
                          a.name.localeCompare(b.name)
                        )}
                        getOptionLabel={option => option.name}
                        onChange={(_, newValue) => {
                          field.onChange(newValue ? newValue.name : '');
                        }}
                        value={
                          cleanerList.find(
                            cleaner => cleaner.name === currentValue
                          ) || null
                        }
                        renderInput={params => (
                          <TextField {...params} label="Cleaners" />
                        )}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Hire Type</InputLabel>
                  <Controller
                    name="hiretype"
                    control={control}
                    defaultValue={defaultValues.hiretype}
                    render={({ field }) => (
                      <Select {...field} label="Hire Type">
                        <MenuItem value="">Select</MenuItem>
                        {hireTypeList.map((hire, index) => (
                          <MenuItem key={index} value={hire}>
                            {hire}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="client"
                  control={control}
                  defaultValue={defaultValues.clientname}
                  render={({ field }) => (
                    <TextField {...field} label="Client Name" fullWidth />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="subagent"
                  control={control}
                  defaultValue={defaultValues.subagent}
                  render={({ field }) => (
                    <TextField {...field} label="Sub Agent" fullWidth />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="repveh"
                  control={control}
                  defaultValue={defaultValues.repveh}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="repveh"
                      label="Rep Veh."
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="rvocode"
                  control={control}
                  defaultValue={defaultValues.rvocode}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="rvocode"
                      label="Rep Code"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="okm"
                  control={control}
                  defaultValue={defaultValues.okm}
                  render={({ field }) => (
                    <TextField
                      type="number"
                      {...field}
                      id="okm"
                      label="Open KM"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="ckm"
                  control={control}
                  defaultValue={defaultValues.ckm}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      id="ckm"
                      label="Close KM"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="tkm"
                  control={control}
                  defaultValue={defaultValues.tkm}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="tkm"
                      type="number"
                      label="Total KM"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: !!field.value,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="akm"
                  control={control}
                  defaultValue={defaultValues.akm}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="akm"
                      type="number"
                      label="Additional Total"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="ttkm"
                  control={control}
                  defaultValue={defaultValues.ttkm}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="ttkm"
                      type="number"
                      label="G. Total"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: !!field.value,
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="billno"
                  control={control}
                  defaultValue={defaultValues.billno}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="billno"
                      type="number"
                      label="Bill No."
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <Typography>Extras :</Typography>
                {extraData.map((item: any, index: number) => (
                  <Controller
                    key={item.id}
                    name={`extras[${index}]`}
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <div>
                        <FormControlLabel
                          {...field}
                          control={
                            <Checkbox
                              checked={Boolean(value?.checked)}
                              onChange={e => {
                                const isChecked = e.target.checked;
                                onChange(
                                  isChecked ? { ...item, checked: true } : false
                                );
                              }}
                            />
                          }
                          label={`${item?.code}`}
                        />
                      </div>
                    )}
                  />
                ))}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="extra_toll"
                  control={control}
                  defaultValue={defaultValues.extra_toll}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="extra_toll"
                      label="Extra Toll"
                      fullWidth
                      type="number"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="tollc"
                  control={control}
                  defaultValue={defaultValues.tollc}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="tollc"
                      label="Extra Comments"
                      fullWidth
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Bill</InputLabel>
                  <Controller
                    name="bill"
                    control={control}
                    defaultValue={defaultValues.bill}
                    render={({ field }) => (
                      <Select {...field} type="number" label="Bill">
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="0">Unbilled</MenuItem>
                        <MenuItem value="1">Billed</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel>Breakdown</InputLabel>
                  <Controller
                    name="break"
                    control={control}
                    defaultValue={defaultValues.break}
                    render={({ field }) => (
                      <Select {...field} label="Breakdown">
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="0">No-Breakdown</MenuItem>
                        <MenuItem value="1">Breakdown</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="particulars"
                  control={control}
                  render={({ field }) => {
                    const hotelInitials =
                      hotels?.map(hotel => hotel.hotel_initial) ?? [];
                    const currentValues = field.value
                      ? field.value.split('/')
                      : [];

                    const handleChange = (_: any, newValue: any[]) => {
                      const newValueString = newValue.join('/');
                      field.onChange(newValueString);
                    };

                    return (
                      <Autocomplete
                        multiple
                        disablePortal
                        options={hotelInitials}
                        value={currentValues}
                        onChange={handleChange}
                        renderInput={params => (
                          <TextField {...params} label="Transfer" />
                        )}
                        renderTags={(tags, getTagProps) =>
                          tags.map((tag, index) => (
                            <Chip
                              label={tag}
                              {...getTagProps({ index })}
                              key={index}
                            />
                          ))
                        }
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="particularsType"
                  defaultValue={defaultValues.particulars_type}
                  label="Particulars Type"
                  {...register('particulars_type')}
                  fullWidth
                  multiline
                  rows={1}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  id="remark"
                  label="Remark"
                  defaultValue={defaultValues.remark}
                  {...register('remark')}
                  fullWidth
                  multiline
                  rows={1}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl component="fieldset">
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Controller
                      name="early"
                      control={control}
                      defaultValue={defaultValues.early}
                      render={({ field }) => (
                        <div>
                          <Checkbox {...field} checked={field.value} />
                          <span>Early</span>
                        </div>
                      )}
                    />
                    <Controller
                      name="early_morning"
                      control={control}
                      defaultValue={defaultValues.early_morning}
                      render={({ field }) => (
                        <div>
                          <Checkbox {...field} checked={field.value} />
                          <span>E. Morning</span>
                        </div>
                      )}
                    />
                    <Controller
                      name="late"
                      control={control}
                      defaultValue={defaultValues.late}
                      render={({ field }) => (
                        <div>
                          <Checkbox {...field} checked={field.value} />
                          <span>Late</span>
                        </div>
                      )}
                    />
                    <Controller
                      name="onite"
                      control={control}
                      defaultValue={defaultValues.onite}
                      render={({ field }) => (
                        <div>
                          <Checkbox {...field} checked={field.value} />
                          <span>O. Nite</span>
                        </div>
                      )}
                    />
                  </div>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} style={{ textAlign: 'end' }}>
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  onClick={handleReset}
                  style={{ marginRight: '10px' }}
                >
                  Reset
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  Add
                </Button>
              </Grid>
            </Grid>
          </Card>
        </div>
      </div>
    </form>
  );
};

export default AddNewHire;
