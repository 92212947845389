import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import MaterialTable from '@material-table/core';

import DeleteDialog from '@components/Dialog/DeleteDialog';
import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useAddAdditional } from '../../hooks/additional';
import {
  useReadBillReg,
  useReadBills,
  useUpdateBill,
  useUpdateBillReg,
  useUpdateBillRegByBillNo,
} from '../../hooks/bill';
import { useReadCompanies } from '../../hooks/company';
// import { useReadExtras } from '../../hooks/extra';
import { useReadHireCharts, useUpdateHireChart } from '../../hooks/hirechart';

// import { useReadPtolls } from '../../hooks/ptoll';

const BillRegisterDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [selectedBill, setSelectedBill] = useState<any>(null);

  const { mutate: updateHirechartMutate } = useUpdateHireChart();
  const { mutate: updateBillMutate } = useUpdateBill();
  const { mutate: updateBillRegByBillNoMutate } = useUpdateBillRegByBillNo();
  const { mutate: updateBillRegMutate } = useUpdateBillReg();
  const { mutate: addAdditionalMutate } = useAddAdditional();

  const [premark, setPremark] = useState('');
  const [paid, setPaid] = useState('0');

  const [discountAmount, setDiscountAmount] = useState('0');
  const [additionalAmount, setAdditionalAmount] = useState('0');
  const [additionalParticular, setAdditionalParticular] = useState('');

  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [netTaxable, setNetTaxable] = useState(0);
  const [gstAmount, setGstAmount] = useState(0);
  const [gstValue, setGstValue] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const { id } = useParams();
  const { data: billList } = useReadBills();

  const [hirechartList, setHirechartList] = useState<any>([]);

  const [details, setDetails] = useState({
    company: '',
    billnum: '',
    cname: '',
    address: '',
    gstno: '',
    panno: '',
    subagent: '',
  });

  const { data: billReg } = useReadBillReg({ id });

  /* const { data: extras } = useReadExtras(); */

  const { data: companies } = useReadCompanies();

  /* const { data: ptolls } = useReadPtolls(); */

  // eslint-disable-next-line
  let { data: hirecharts, isLoading, refetch } = useReadHireCharts() as any;

  useEffect(() => {
    document.title = 'Bill - nunes';
    if (billReg) {
      setPremark(billReg.premark);
      setPaid(billReg.paid);
    }
  }, [billReg]);

  useEffect(() => {
    if (hirecharts && billList) {
      // eslint-disable-next-line
      hirecharts = hirecharts?.filter(
        (h: any) => Number(h.billno) === Number(id)
      );

      // eslint-disable-next-line
      hirecharts = hirecharts?.map((h: any) => {
        const bill: any = billList?.find(b => Number(b.hid) === Number(h.id));

        return {
          billData: bill,
          hirechartData: h,
        };
      });

      if (hirecharts && hirecharts.length) {
        const companyData = companies?.filter(
          c => hirecharts![0]?.company === c.name
        );
        if (companyData && companyData.length) {
          setDetails({
            company: companyData[0].name,
            billnum: companyData[0].billnum,
            cname: companyData[0].cname,
            address: companyData[0].address,
            gstno: companyData[0].gstno,
            panno: companyData[0].panno,
            subagent: companyData[0].subagent,
          });

          setGstValue(Number(companyData[0].gst));
        }

        let billTotal = 0;
        hirecharts.forEach((h: any) => {
          billTotal = Number(billTotal) + Number(h?.billData?.tot3 || 0);
        });

        setTotal(billTotal);
        setHirechartList(hirecharts);
        setDiscount(Number(hirecharts[0]?.billData?.discount || 0));
        setNetTaxable(total - discount);
        setGstAmount(netTaxable * (gstValue / 100));
        setGrandTotal(netTaxable + gstAmount);
      }
    }
  }, [hirecharts, billList]);

  const navigate = useNavigate();

  // if (isError) {
  //   return <div>Error fetching Proforma</div>;
  // }

  const handleEdit = (rowData: any) => {
    if (rowData) {
      navigate(`/billRegister/${rowData.billData.id}/edit`);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = (rowData: any) => {
    setSelectedBill(rowData);
    setDeleteDialogOpen(true);
  };

  const updateContractDetails = () => {
    updateBillRegMutate(
      { premark, paid, id },
      {
        onSuccess: () => {
          enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      }
    );
  };

  const updateAdditionalDetails = () => {
    addAdditionalMutate(
      {
        billNo: id,
        particular: additionalParticular,
        amount: additionalAmount,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      }
    );
  };

  const updateDiscountDetails = () => {
    updateBillRegByBillNoMutate(
      { billNum: id, discount: discountAmount },
      {
        onSuccess: () => {
          enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      }
    );
  };

  const handleConfirmDelete = () => {
    if (selectedBill) {
      updateHirechartMutate(
        { id: +selectedBill.hirechartData.id, billno: '0' },
        {
          onSuccess: () => {
            updateBillMutate(
              { id: +selectedBill.billData.id, billed: 0 },
              {
                onSuccess: () => {
                  updateBillRegMutate(
                    {
                      id: +selectedBill.hirechartData.billno,
                      total:
                        Number(grandTotal) -
                        Number(selectedBill?.billData?.tot3 || 0),
                    },
                    {
                      onSuccess: () => {
                        setGrandTotal(
                          Number(grandTotal) -
                            Number(selectedBill?.billData?.tot3 || 0)
                        );
                        enqueueSnackbar('Content Deleted', {
                          variant: 'success',
                        });
                        refetch();
                      },
                    }
                  );
                },
              }
            );
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  //   proformaList?.map((p: any) => {
  //     let hc: any = hirechartList?.find(h => h.id === p.hid);

  //     if (!hc) {
  //       hc = contractList?.find(h => h.id === p.hid);
  //     }
  //     p.date = hc?.date;
  //     p.vtype = hc?.vtype;
  //     p.vehno = hc?.vno;
  //     p.particular = hc?.particulars;
  //     return p;
  //   });

  const columns: any = [
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: (rowData: any) => `${formatDate(rowData?.hirechart?.date)} `,
    },
    {
      title: 'Vehicle Type',
      field: 'vtype',
      render: (rowData: any) => `${rowData?.hirechart?.vtype} `,
    },
    {
      title: 'Vehicle No',
      field: 'vehicleno',
      render: (rowData: any) => `${rowData?.hirechart?.vno || ''} `,
    },
    {
      title: 'Particular',
      render: (rowData: any) =>
        `${rowData?.hirechart?.particulars} ${rowData?.hirechart?.particulars_type}`,
    },
    {
      title: '8 Hrs / 100 KMs',
      field: 'kms',
      render: (rowData: any) => `${rowData?.bill?.kms || 0} `,
    },
    {
      title: 'Ex Kms',
      field: 'xkms',
      render: (rowData: any) => `${rowData?.bill?.xkms || 0} `,
    },
    {
      title: 'Rate',
      field: 'rate',
      render: (rowData: any) => `${rowData?.bill?.rate || 0} `,
    },
    {
      title: 'Tot',
      field: 'tot1',
      render: (rowData: any) => `${rowData?.bill?.tot1 || 0} `,
    },
    {
      title: 'Ex HRs',
      field: 'xhrs',
      render: (rowData: any) => `${rowData?.bill?.xhrs || 0} `,
    },
    {
      title: 'Wait Ch',
      field: 'wchr',
      render: (rowData: any) => `${rowData?.bill?.wchr || 0} `,
    },
    {
      title: 'Tot',
      field: 'tot2',
      render: (rowData: any) => `${rowData?.bill?.tot2 || 0} `,
    },
    {
      title: 'Early',
      field: 'early',
      render: (rowData: any) => `${rowData?.bill?.early || 0} `,
    },
    {
      title: 'Early Morning',
      field: 'early_morning',
      render: (rowData: any) => `${rowData?.bill?.early_morning || 0} `,
    },
    {
      title: 'Late',
      field: 'late',
      render: (rowData: any) => `${rowData?.bill?.late || 0} `,
    },
    {
      title: 'Nite',
      field: 'onite',
      render: (rowData: any) => `${rowData?.bill?.onite || 0} `,
    },
    {
      title: 'Extra',
      field: 'toll',
      render: (rowData: any) => `${rowData?.bill?.toll || 0} `,
    },
    {
      title: 'Total',
      field: 'tot3',
      render: (rowData: any) => `${rowData?.bill?.tot3 || 0} `,
    },
    {
      title: 'billId',
      field: 'billId',
      hidden: true,
      render: (rowData: any) => `${rowData?.billData?.id} `,
    },
    {
      title: 'billNo',
      field: 'billNo',
      hidden: true,
      render: (rowData: any) => `${rowData?.hirechartData?.billno} `,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="Bill Register Details" />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item xs={3} md={3} marginLeft={2} marginTop={4}>
              <Typography variant="h6">
                Date: {formatDate(billReg?.date)}
              </Typography>
              <Typography variant="h6">Bill No: {billReg?.billnum}</Typography>
              <Typography variant="h6">Company: {details?.company}</Typography>
              <Typography variant="h6">
                Parent Company: {details?.cname}
              </Typography>
              <Typography variant="h6">Address: {details?.address}</Typography>
              <Typography variant="h6">GST Number: {details?.gstno}</Typography>
              <Typography variant="h6">PAN Number: {details?.panno}</Typography>
              <Typography variant="h6">
                Subagent: {details?.subagent}
              </Typography>
              <Typography variant="h6">Ref: {billReg?.ref}</Typography>
            </Grid>
            <Grid
              item
              xs={7}
              md={7}
              container
              direction="column"
              marginLeft={14}
              marginTop={4}
            >
              <Grid item py={2} marginLeft={14}>
                <Select
                  labelId="paid"
                  id="paid"
                  value={paid}
                  onChange={e => setPaid(e.target.value)}
                  label="Paid"
                >
                  <MenuItem value="0">Unpaid</MenuItem>
                  <MenuItem value="1">Paid</MenuItem>
                </Select>
              </Grid>
              <Grid item py={4} marginLeft={14}>
                <TextField
                  id="premark"
                  value={premark}
                  onChange={e => setPremark(e.target.value)}
                  label="Remarks"
                  variant="outlined"
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item marginLeft={14}>
                <Button variant="contained" onClick={updateContractDetails}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={hirechartList || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Bill',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Bill',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>

          <Grid
            item
            container
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            paddingRight={4}
          >
            <Grid item>
              <strong>Total (Rs.) </strong>
              {total}
            </Grid>
            <Grid item>
              <strong>Less </strong>
              {discount}
            </Grid>
            <Grid item>
              <strong>Net Taxable </strong>
              {netTaxable}
            </Grid>
            <Grid item>
              <strong>Add {gstValue}</strong>
              {gstAmount}
            </Grid>
            <Grid item>
              <strong>G. Total </strong>
              {grandTotal}
            </Grid>
          </Grid>

          <Box>
            <Box sx={{ mt: 2, mb: 2 }} marginLeft={2}>
              <Typography style={{ marginLeft: '4px' }} variant="h6">
                Add Additional Charges
              </Typography>
              <TextField
                id="additionalParticular"
                value={additionalParticular}
                onChange={e => setAdditionalParticular(e.target.value)}
                label="Particular"
                variant="outlined"
                style={{ marginLeft: '12px' }}
              />
              <TextField
                id="additionalAmount"
                value={additionalAmount}
                onChange={e => setAdditionalAmount(e.target.value)}
                label="Amount"
                variant="outlined"
                style={{ marginLeft: '12px' }}
              />
              <Button
                style={{ marginLeft: '12px' }}
                variant="contained"
                onClick={updateAdditionalDetails}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box>
            <Box sx={{ mt: 2, mb: 2 }} marginLeft={2}>
              <Typography style={{ marginLeft: '4px' }} variant="h6">
                Discount
              </Typography>
              <TextField
                id="amount"
                value={discountAmount}
                onChange={e => setDiscountAmount(e.target.value)}
                label="Amount"
                variant="outlined"
                style={{ marginLeft: '12px' }}
              />
              <Button
                style={{ marginLeft: '12px' }}
                variant="contained"
                onClick={updateDiscountDetails}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default BillRegisterDetail;
