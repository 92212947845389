import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import EditContracts from '@components/Contracts/EditContracts';
import NewContracts from '@components/Contracts/NewContracts';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import {
  useAddContract,
  useDeleteContract,
  useEditContract,
  useReadContracts,
} from '../../hooks/contract';
import { IContract } from '../../types/contract';

const Contract = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: contracts, isLoading, refetch } = useReadContracts();

  const [localContracts, setLocalContracts] = useState<IContract[]>([]);
  const [contract, setContract] = useState<IContract | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();

  const { mutate: deleteContractMutate } = useDeleteContract();
  const { mutate: addContractMutate } = useAddContract();
  const { mutate: editContractMutate } = useEditContract();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Contract - nunes';
    if (contracts) {
      const contractsWithSr = contracts.map((contractItems, index) => ({
        ...contractItems,
        sr: index + 1,
      }));
      setLocalContracts(contractsWithSr);
    }
  }, [contracts]);

  const handleDetails = (rowData: IContract | undefined) => {
    console.log('rowData', rowData);
    if (rowData) {
      navigate(`/contractDetails/${rowData.id}`);
    }
  };

  const handleEdit = (rowData: any) => {
    setContract(rowData);
    setDefaultvalue({
      date: rowData?.date,
      company: rowData?.company,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setContract(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (contract && action && action?.action) {
      editContractMutate(
        { ...action?.formData, id: +contract.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (contract) {
      deleteContractMutate(
        { id: +contract.id },
        {
          onSuccess: () => {
            setLocalContracts(prevContracts =>
              prevContracts.filter(c => c.id !== contract.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', {
              variant: 'error',
            });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addContractMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: () => {
          enqueueSnackbar('Failed to Add Content', { variant: 'error' });
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<IContract>[] = [
    {
      title: 'S.No',
      field: 'sr', // Use the 'sr' field for serial numbers
      sorting: true, // Disable sorting for serial number column
      width: '5%',
    },
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: rowData => `${formatDate(rowData?.date)}`,
    },
    {
      title: 'Bill No.',
      field: 'billno',
      render: rowData => `${rowData?.billno} `,
    },
    {
      title: 'Company',
      field: 'company',
      render: rowData => `${rowData?.company} `,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Contracts"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Contract
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localContracts || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              onRowClick={(_, rowData) => handleDetails(rowData)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Contract',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Contract',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={NewContracts}
      />

      {/* <DetailDialog
        open={drawerOpen}
        handleCloseDrawer={() => setDrawerOpen(false)}
        ContentComponent={ContractDetails}
        data={contract}
      /> */}

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditContracts}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default Contract;
