import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import MaterialTable from '@material-table/core';

import AddCPart from '@components/Contracts/AddCPart';
import EditCPart from '@components/Contracts/EditCPart';
import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useAddBillReg, useGetBillRegByLastCreated } from '../../hooks/bill';
import { useReadCompanies } from '../../hooks/company';
import {
  useAddCPart,
  useDeleteCPart,
  useEditContract,
  useEditCPart,
  useReadContractMulti,
  useReadCpart,
  useUpdateBillReg,
} from '../../hooks/contract';
import { IReadContract } from '../../types/contract';

const ContractDetails = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useParams();
  const navigate = useNavigate();

  const [premark, setPremark] = useState('');
  const [paid, setPaid] = useState('0');

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedC, setSelectedC] = useState<any>(null);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { data, isLoading, isError } = useReadContractMulti({
    id,
  } as IReadContract);

  const { data: companies } = useReadCompanies();

  const [company, setCompany] = useState<any>(null);

  const { data: lastCreatedBillReg } = useGetBillRegByLastCreated();

  useEffect(() => {
    document.title = 'Contract - nunes';
    if (data && data?.billReg) {
      setPaid(data.billReg.paid);
      setPremark(data.billReg.premark);
    }

    if (data && companies) {
      const c = companies.find(cp => cp.name === data.company);
      setCompany(c);
    }
  }, [data, companies]);

  const { data: cpartList, refetch } = useReadCpart(id as string);

  let total = 0;
  // eslint-disable-next-line
  cpartList?.map(cp => (total += Number(cp?.amount || 0)));
  const netTaxable = total - (cpartList ? Number(cpartList[0]?.discount) : 0);
  const gstValue = 0;
  const discount = 0;
  if (company) Number(company?.gst);
  const gstAmount = netTaxable * (gstValue !== 0 ? gstValue / 100 : 0);
  let grandTotal = netTaxable;
  if (company?.gstType !== '3') grandTotal = netTaxable + gstAmount;

  const { mutate: addBillRegMutate } = useAddBillReg();
  const { mutate: updateBillRegMutate } = useUpdateBillReg();
  const { mutate: useDeleteCPartMutate } = useDeleteCPart();
  const { mutate: useEditCPartMutate } = useEditCPart();
  const { mutate: useAddCPartMutate } = useAddCPart();
  const { mutate: useEditContractMutate } = useEditContract();

  if (isError) {
    return <div>Error fetching Contract</div>;
  }

  const updateContractDetails = () => {
    updateBillRegMutate(
      { premark, paid, id: data?.contract?.billno },
      {
        onSuccess: () => {
          enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      }
    );
  };

  const makeBill = () => {
    if (
      !data?.contract?.billno ||
      data?.contract?.billno === '0' ||
      data?.contract?.billno === ''
    ) {
      let billCount = 101;
      if (lastCreatedBillReg) {
        billCount = Number(lastCreatedBillReg.id) + 1;
      }
      const currentYear = new Date().getFullYear().toString().slice(-2);
      const lastYear = Number(currentYear) - 1;
      const billNo = `JPN${lastYear}${currentYear}/${billCount}`;
      if (data?.contract) {
        data.contract.billno = `JPN/${billCount}`;
        data.contract.fullBillNo = billNo;
      }

      addBillRegMutate(
        {
          id: billCount,
          date: new Date(),
          billnum: billNo,
          type: '2',
          total: grandTotal,
          company: data?.contract?.company,
        },
        {
          onSuccess: () => {
            // eslint-disable-next-line
            useEditContractMutate(
              { billno: billCount, id },
              {
                onSuccess: () => {
                  localStorage.setItem('contract', JSON.stringify(data));
                  localStorage.setItem('cpartList', JSON.stringify(cpartList));
                  navigate(`/contracts/view`);
                },
                onError: () => {
                  enqueueSnackbar('Failed to Update Content', {
                    variant: 'error',
                  });
                },
              }
            );
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      data.contract.fullBillNo = data?.billReg.billnum;
      localStorage.setItem('contract', JSON.stringify(data));
      localStorage.setItem('cpartList', JSON.stringify(cpartList));
      navigate(`/contracts/view`);
    }
  };

  // const handlePrint = () => {
  //   if (document) {
  //     const printContent: string | undefined =
  //       document.getElementById('printData')?.innerHTML;
  //     if (printContent) {
  //       const originalContent: string = document.body.innerHTML;
  //       document.body.innerHTML = printContent;
  //       window.focus();
  //       window.print();
  //       document.body.innerHTML = originalContent;
  //     }
  //   }
  // };'

  const handleEdit = (rowData: any) => {
    setSelectedC(rowData);
    setDefaultvalue({
      vtype: rowData?.vtype,
      kms: rowData?.kms,
      particular: rowData?.particular,
      amount: rowData?.amount,
      rate: rowData?.rate,
    });
    setEditDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = (rowData: any) => {
    setSelectedC(rowData);
    setDeleteDialogOpen(true);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      // eslint-disable-next-line
      useAddCPartMutate(
        { ...action?.formData, cid: id },
        {
          onSuccess: () => {
            refetch();
            setAddDialogOpen(false);
          },
          onError: () => {
            enqueueSnackbar('Failed to Add Content', { variant: 'error' });
          },
        }
      );
    } else {
      setAddDialogOpen(false);
    }
  };

  const handleConfirmDelete = () => {
    if (selectedC) {
      // eslint-disable-next-line
      useDeleteCPartMutate(
        { id: +selectedC.id },
        {
          onSuccess: () => {
            enqueueSnackbar('Content Deleted', { variant: 'success' });
            refetch();
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleCloseEditDialog = (action: any) => {
    if (selectedC && action && action?.action) {
      // eslint-disable-next-line
      useEditCPartMutate(
        { ...action?.formData, id: +selectedC.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const columns: any = [
    {
      title: 'Vehicle',
      field: 'vtype',
      render: (rowData: any) => `${rowData?.vtype} `,
    },
    {
      title: 'Particulars',
      render: (rowData: any) =>
        `${rowData?.particular} ${rowData.particulars_type} `,
    },
    {
      title: 'KMs/No of nights',
      field: 'kms',
      render: (rowData: any) => `${rowData?.kms || ''} `,
    },
    {
      title: 'Rate',
      field: 'rate',
      render: (rowData: any) => `${rowData?.rate || ''} `,
    },
    {
      title: 'Amount',
      field: 'amount',
      render: (rowData: any) => `${rowData?.amount || ''} `,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Contract Details"
          // primaryAction={
          //   // <Button variant="contained" color="primary" onClick={handlePrint}>
          //   <Button variant="contained" color="primary">
          //     Print
          //   </Button>
          // }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12} sx={{ p: 2 }}>
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <div id="printData">
                {data?.contract?.billno && (
                  <Box sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <Typography variant="h6">
                          Date: {formatDate(data?.contract?.date)}
                        </Typography>
                        <Typography variant="h6">
                          Company: {data?.contract?.company}
                        </Typography>
                        <Typography variant="h6">
                          Bill No: JPN/{data?.contract?.billno}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box>
                          <Box sx={{ mt: 2 }}>
                            <Select
                              labelId="paid"
                              id="paid"
                              value={paid}
                              onChange={e => setPaid(e.target.value)}
                              label="Paid"
                            >
                              <MenuItem value="0">Unpaid</MenuItem>
                              <MenuItem value="1">Paid</MenuItem>
                            </Select>
                          </Box>
                          <Box sx={{ mt: 2, mb: 2 }}>
                            <TextField
                              id="premark"
                              value={premark}
                              onChange={e => setPremark(e.target.value)}
                              label="Remarks"
                              variant="outlined"
                              multiline
                              rows={3}
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Button
                            variant="contained"
                            onClick={updateContractDetails}
                          >
                            Update
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                <Button variant="contained" onClick={handleAddDialogOpen}>
                  Add New
                </Button>
                <Button variant="contained" onClick={makeBill}>
                  Make Bill
                </Button>
                <Grid item xs={12}>
                  <MaterialTable
                    title=""
                    columns={columns}
                    data={cpartList || []}
                    isLoading={isLoading}
                    options={{
                      draggable: false,
                      paging: false,
                      search: true,
                      tableLayout: 'fixed',
                      searchFieldVariant: 'outlined',
                    }}
                    style={{
                      minHeight: 'calc(100vh - 180px)',
                      overflowY: 'auto',
                    }}
                    components={{
                      Container: props => <Paper elevation={0} {...props} />,
                    }}
                    actions={[
                      {
                        icon: EditIcon,
                        tooltip: 'Edit Bill',
                        onClick: (_, rowData) => handleEdit(rowData),
                      },
                      {
                        icon: DeleteIcon,
                        tooltip: 'Delete Bill',
                        onClick: (_, rowData) => handleDelete(rowData),
                      },
                    ]}
                  />
                </Grid>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  paddingRight={4}
                >
                  <Grid item>
                    <strong>Total (Rs.) </strong>
                    {total}
                  </Grid>
                  <Grid item>
                    <strong>Less </strong>
                    {discount}
                  </Grid>
                  <Grid item>
                    <strong>Net Taxable </strong>
                    {netTaxable}
                  </Grid>
                  <Grid item>
                    <strong>G. Total </strong>
                    {grandTotal}
                  </Grid>
                </Grid>
              </div>
            )}
          </Grid>

          <AddDialog
            open={addDialogOpen}
            handleClose={handleAddDialogClose}
            ContentComponent={AddCPart}
          />

          <DeleteDialog
            open={deleteDialogOpen}
            handleClose={handleCloseDeleteDialog}
            handleConfirmDelete={handleConfirmDelete}
          />

          <EditDialog
            open={editDialogOpen}
            handleClose={handleCloseEditDialog}
            ContentComponent={EditCPart}
            defaultvalue={defaultvalue}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ContractDetails;
