import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import DeleteDialog from '@components/Dialog/DeleteDialog';
import DetailDialog from '@components/Dialog/DetailDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';
import EditProforma from '@components/Proforma/EditProforma';
import ProformaDetail from '@components/Proforma/ProformaDetail';

import { formatDate } from '../../helpers/dateFormat';
import {
  useDeleteProformaReg,
  useEditProforma,
  useReadProformaRegs,
} from '../../hooks/proforma';
import { IProforma } from '../../types/proforma';

const Proforma = () => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: proformas, isLoading } = useReadProformaRegs();

  const [localproforma, setLocalProforma] = useState<IProforma[]>([]);
  const [proforma, setProforma] = useState<IProforma | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  // const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { mutate: deleteProformaRegMutate } = useDeleteProformaReg();
  // const { mutate: addProformaMutate } = useAddProforma();
  const { mutate: editProformaMutate } = useEditProforma();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Proforma - nunes';
    if (proformas) {
      const proformaWithSr = proformas.map((proformasItems, index) => ({
        ...proformasItems,
        sr: index + 1,
      }));
      setLocalProforma(proformaWithSr);
    }
  }, [proformas]);

  const handleDetails = (rowData: any) => {
    if (rowData) {
      navigate(`/proformaDetails/${rowData.id}`);
    }
  };

  // const handleEdit = (rowData: IProforma) => {
  //   setProforma(rowData);
  //   setEditDialogOpen(true);
  //   enqueueSnackbar('Edit Modal', { variant: 'info' });
  // };

  const handleDelete = (rowData: any) => {
    setProforma(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (action && action?.action) {
      editProformaMutate(action?.formData, {
        onSuccess: () => {
          setEditDialogOpen(false);
          enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Delete Content', { variant: 'error' });
        },
      });
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (proforma) {
      deleteProformaRegMutate(
        { id: +proforma.id },
        {
          onSuccess: () => {
            setLocalProforma(prevProforma =>
              prevProforma.filter(c => c.id !== proforma.id)
            );
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  // const handleAddDialogOpen = () => {
  //   setAddDialogOpen(true);
  // };

  // const handleAddDialogClose = (action: any) => {
  //   if (action && action?.action) {
  //     addProformaMutate(action?.formData, {
  //       onSuccess: data => {
  //         const newProforma = data.data;
  //         setLocalProforma(prevProforma => [...prevProforma, newProforma]);
  //         setAddDialogOpen(false);
  //       },
  //       onError: error => {},
  //     });
  //   } else {
  //     setAddDialogOpen(false);
  //   }
  // };

  const columns: Column<IProforma>[] = [
    {
      title: 'S.No',
      field: 'sr',
      sorting: true,
      width: '5%',
    },
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: (rowData: any) => `${formatDate(rowData?.date)}`,
    },
    {
      title: 'Proforma No',
      field: 'proformanum',
      render: (rowData: any) => `${rowData?.proformanum} `,
    },
    {
      title: 'Type',
      field: 'type',
      render: (rowData: any) =>
        `${rowData?.type === '1' ? 'Hire' : 'Contract'} `,
    },
    {
      title: 'Company',
      field: 'company',
      render: (rowData: any) => `${rowData?.company || ''} `,
    },
    {
      title: 'Total',
      field: 'total',
      render: (rowData: any) => `${rowData?.total} `,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Proforma"
          // primaryAction={
          //   <Button
          //     variant="contained"
          //     color="primary"
          //     onClick={handleAddDialogOpen}
          //   >
          //     <AddIcon sx={{ mr: 1 }} />
          //     Add Proforma
          //   </Button>
          // }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localproforma || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              onRowClick={(_, rowData) => handleDetails(rowData)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Proforma',
                  onClick: (_, rowData) => handleDetails(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Proforma',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <DetailDialog
        open={drawerOpen}
        handleCloseDrawer={() => setDrawerOpen(false)}
        ContentComponent={ProformaDetail}
        data={proforma}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditProforma}
        billMade={false}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default Proforma;
