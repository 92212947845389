import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useUpdateBillByHid } from '../../hooks/bill';
import { useReadExtras } from '../../hooks/extra';
import {
  useReadHireChartMulti,
  useReadRates,
  useUpdateHireChart,
} from '../../hooks/hirechart';
import {
  useAddPtoll,
  useDeletePtollByHid,
  useReadPtolls,
} from '../../hooks/ptoll';
import { useReadSubagents } from '../../hooks/subagent';
import { useGetTariff } from '../../hooks/tariff';
import { useReadTransfers } from '../../hooks/transfer';

const BillRegisterEdit = () => {
  const { id } = useParams();
  // const { control, getValues, setValue } = useFormContext();

  const { enqueueSnackbar } = useSnackbar();

  const [particulars, setParticulars] = useState('');
  const [particulars_type, setParticularsType] = useState('');
  const [premark, setPremark] = useState('');
  const [bmode, setBmode] = useState('1');
  const [date, setDate] = useState('');
  const [company, setCompany] = useState('');
  const [repveh, setRepVeh] = useState('');
  const [owncode, setOwncode] = useState('');
  // setOwncode('');
  const [ac, setAc] = useState('');
  const [vehNo, setVehNo] = useState('');
  const [vehType, setVehType] = useState('');

  const [minType, setMinType] = useState('1');
  const [kms, setKms] = useState('0');
  const [extraKms, setExtraKms] = useState('');
  const [totalExtraKms, setTotalExtraKms] = useState('');
  const [extraHrs, setExtraHrs] = useState('');
  const [totalWaiting, setTotalWaiting] = useState('');

  const [rate, setRate] = useState('');
  const [waitChgs, setWaitChgs] = useState('');

  const [early, setEarly] = useState('');
  const [earlyMorning, setEarlyMorning] = useState('');
  const [late, setLate] = useState('');
  const [overnight, setOvernight] = useState('');

  const [earlyCheckbox, setEarlyCheckbox] = useState(false);
  const [earlyMorningCheckbox, setEarlyMorningCheckbox] = useState(false);
  const [lateCheckbox, setLateCheckbox] = useState(false);
  const [niteCheckbox, setNiteCheckbox] = useState(false);

  const [additionalAmount1, setAdditionalAmount1] = useState('');
  const [additionalAmount2, setAdditionalAmount2] = useState('');
  const [additionalAmount3, setAdditionalAmount3] = useState('');
  const [additionalAmount4, setAdditionalAmount4] = useState('');

  const [additionalChargs1, setAdditionalChargs1] = useState('');
  const [additionalChargs2, setAdditionalChargs2] = useState('');
  const [additionalChargs3, setAdditionalChargs3] = useState('');
  const [additionalChargs4, setAdditionalChargs4] = useState('');

  const [transfer, setTransfer] = useState('');
  const [selectedTransferData, setSelectedTransferData] = useState([]);

  const [subagent, setSubagent] = useState('');
  const [client, setClient] = useState('');

  const [extraComment, setExtraComment] = useState('');

  const [extrasToll, setExtrasToll] = useState('');

  const [extraCheckbox, setExtraCheckbox] = useState<any>([]);
  const [extrasData, setExtrasData] = useState<any>([]);

  const { data, isLoading, isError, refetch } = useReadHireChartMulti({
    id,
  } as any);

  const { data: transferData } = useReadTransfers();

  const { data: transferTariffs } = useGetTariff({
    companyName: company ?? '',
  });

  const { data: subagentData } = useReadSubagents();

  const { data: extraData } = useReadExtras();

  const { data: ratesData } = useReadRates();

  const { data: ptollsData } = useReadPtolls();

  useEffect(() => {
    if (
      transferData &&
      transferData.length &&
      transferTariffs &&
      transferTariffs.length
    ) {
      const d: any = transferData?.filter(
        transf =>
          transferTariffs?.findIndex(
            (tarriff: any) => Number(tarriff.tid) === Number(transf.id)
          ) !== -1
      );

      console.log('detailedit', d);
      if (d && d.length) {
        setSelectedTransferData(d);
      }
    }
  }, [transferData, transferTariffs]);

  useEffect(() => {
    if (selectedTransferData && selectedTransferData.length && data) {
      if (data?.bill?.tid && data?.bill?.tid !== '0') {
        setTransfer(data.bill?.tid);
      } else {
        setTransfer('');
      }
    }
  }, [selectedTransferData, data]);

  useEffect(() => {
    console.log('daaaaataaa', data);
    // localStorage.setItem('hirechartDetails', JSON.stringify(data?.hirechart));
    if (data) {
      if (data?.bill) {
        setMinType(data?.bill?.mintype);
        setKms(data?.bill?.kms);
        setExtraKms(data?.bill?.xkms);
        setTotalExtraKms(data?.bill?.tot1);
        setTotalWaiting(data?.bill?.tot2);
        setRate(data?.bill?.rate);
        setExtraHrs(data?.bill?.xhrs);
        setWaitChgs(data?.bill?.wchr);
        setEarly(data?.bill?.early);
        setEarlyMorning(data?.bill?.early_morning);
        setLate(data?.bill?.late);
        setOvernight(data?.bill?.onite);

        // setBmode(data.bill?.mode);
        setExtraComment(data.hirechart?.tollc);

        setOwncode('');
        setRepVeh('');

        // eslint-disable-next-line
        setEarlyCheckbox(data.hirechart?.early === '1' ? true : false);
        setEarlyMorningCheckbox(
          // eslint-disable-next-line
          data.hirechart?.early_morning === '1' ? true : false
        );
        // eslint-disable-next-line
        setLateCheckbox(data.hirechart?.late === '1' ? true : false);
        // eslint-disable-next-line
        setNiteCheckbox(data.hirechart?.onite === '1' ? true : false);

        setDate(data?.hirechart?.date);
        setCompany(data?.hirechart?.company);
        setAc(data?.hirechart?.ac);
        if (data?.hirechart?.subagent && data?.hirechart?.subagent !== '0') {
          setSubagent(data?.hirechart?.subagent);
        } else {
          setSubagent('');
        }
        setClient(data?.hirechart?.client);
        setVehNo(data?.hirechart?.vno);
        setVehType(data?.hirechart?.vtype);
        setParticulars(data?.hirechart?.particulars);
        setParticularsType(data?.hirechart?.particulars_type);
        setPremark(data?.hirechart?.remark);

        setAdditionalAmount1(data?.hirechart?.additional_amount1);
        setAdditionalAmount2(data?.hirechart?.additional_amount2);
        setAdditionalAmount3(data?.hirechart?.additional_amount3);
        setAdditionalAmount4(data?.hirechart?.additional_amount4);

        setAdditionalChargs1(data?.hirechart?.additional_chargs1);
        setAdditionalChargs2(data?.hirechart?.additional_chargs2);
        setAdditionalChargs3(data?.hirechart?.additional_chargs3);
        setAdditionalChargs4(data?.hirechart?.additional_chargs4);
      }
    }
  }, [data]);

  useEffect(() => {
    setExtrasData(extraData);

    if (ptollsData && extraData) {
      const extraCheckboxArr: any = [];
      // eslint-disable-next-line
      const selectedTolls = ptollsData?.filter(
        p => Number(p.hid) === Number(id)
      );
      extraData?.map((ex: any) => {
        if (selectedTolls?.find(p => Number(p.eid) === Number(ex.id))) {
          extraCheckboxArr.push(true);
        } else {
          extraCheckboxArr.push(false);
        }

        return null;
      });
      setExtraCheckbox(extraCheckboxArr);
      const selectedExtrasSum = selectedTolls?.reduce(
        (acc: any, cum: any) => acc + (cum?.rate ? +cum.rate : 0),
        0
      );
      // eslint-disable-next-line no-unsafe-optional-chaining
      if (data?.hirechart?.toll) {
        setExtrasToll(
          (
            (data?.hirechart?.toll ? +data.hirechart.toll : 0) -
            selectedExtrasSum
          ).toString()
        );
      }
    }
  }, [extraData, ptollsData, data, id]);

  const handleTransferChange = (e: any) => {
    setTransfer(e.target.value);
    const filterSelectedTransferData = transferData?.find(
      (t: any) => t.tid === e.target.value && t.vid === vehType
    );

    if (filterSelectedTransferData?.id) {
      setRate(filterSelectedTransferData?.rate);
    }
  };

  useEffect(() => {
    if (bmode === '2') {
      const rateVal = ratesData?.find(
        (r: any) =>
          r.cid === data.hirechart.company && r.vid === data.hirechart.vtype
      );
      if (rateVal) {
        // setKms(rateVal.initial);
        setKms(rateVal.xkm);
        // setRate(rateVal.xkm);

        setWaitChgs(rateVal.xhr);
        if (rateVal?.mintype === '1' || rateVal?.mintype === '2') {
          setMinType(rateVal?.mintype);
        }

        setEarly(rateVal?.early);
        setEarlyMorning(rateVal?.early_morning);
        setOvernight(rateVal?.onite);
        setLate(rateVal?.late);
      }
    }
    // eslint-disable-next-line
  }, [bmode, ratesData, data]);

  useEffect(() => {
    if (bmode === '1') {
      const rateVal = transferTariffs?.find(
        (r: any) => r.tid === transfer && r.vid === data.hirechart.vtype
      );

      if (rateVal) {
        setRate(rateVal.rate);
      }
    }
    // eslint-disable-next-line
  }, [transfer]);

  const { mutate: updateBillsMutate } = useUpdateBillByHid();
  const { mutate: updateHirechartMutate } = useUpdateHireChart();
  const { mutate: useDeletePtollByHidMutate } = useDeletePtollByHid();
  const { mutate: useAddPtollMutate } = useAddPtoll();

  if (isError) {
    return <div>Error fetching Bill</div>;
  }

  const updateBillDetails = () => {
    const extrasSum = extrasData
      .map((ex: any, index: number) => (extraCheckbox[index] ? +ex.rate : 0))
      .reduce((acc: any, cum: any) => acc + cum, 0);

    const totalToll = Number(extrasToll) + extrasSum;

    updateBillsMutate(
      {
        id: data?.hirechart.billno,
        hid: id,
        mode: bmode,
        mintype: minType,
        kms,
        xkms: extraKms,
        rate,
        tot1: Number(extraKms) * Number(kms),
        xhrs: extraHrs,
        wchr: waitChgs,
        tot2: Number(extraHrs) * Number(waitChgs),
        early,
        early_morning: earlyMorning,
        late,
        onite: overnight,
        toll: totalToll,
        ptoll: extraComment,
        tid: transfer,
        tot3:
          Number(rate) +
          Number(extraKms) * Number(kms) +
          Number(extraHrs) * Number(waitChgs) +
          Number(early) +
          Number(earlyMorning) +
          Number(late) +
          Number(overnight) +
          Number(totalToll),
      },
      {
        onSuccess: () => {
          // enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      }
    );

    updateHirechartMutate(
      {
        id,
        early: earlyCheckbox === true ? 1 : 0,
        early_morning: earlyMorningCheckbox === true ? 1 : 0,
        late: lateCheckbox === true ? 1 : 0,
        onite: niteCheckbox === true ? 1 : 0,
        toll: totalToll,
        tollc: extraComment,
        bill: 1,
        particulars,
        particulars_type,
        remark: premark,
        client,
        subagent,
        additional_amount1: additionalAmount1,
        additional_chargs1: additionalChargs1,
        additional_amount2: additionalAmount2,
        additional_chargs2: additionalChargs2,
        additional_amount3: additionalAmount3,
        additional_chargs3: additionalChargs3,
        additional_amount4: additionalAmount4,
        additional_chargs4: additionalChargs4,
      },
      {
        onSuccess: res => {
          console.log('resss', res);
        },

        onError: () => {},
      }
    );

    // eslint-disable-next-line
    useDeletePtollByHidMutate(
      {
        hid: id,
      },
      {
        onSuccess: () => {
          const addPtollArr: any = [];
          extraData?.map((ex: any, index: number) => {
            if (extraCheckbox[index]) {
              addPtollArr.push({
                hid: id,
                eid: ex.id,
                rate: ex.rate,
              });
            }
            return null;
          });
          // eslint-disable-next-line
          useAddPtollMutate(addPtollArr, {
            onSuccess: () => {
              enqueueSnackbar('Content Updated', { variant: 'info' });
              refetch();
            },
          });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      }
    );
  };

  // const handlePrint = () => {
  //   if (document) {
  //     const printContent: string | undefined =
  //       document.getElementById('printData')?.innerHTML;
  //     if (printContent) {
  //       const originalContent: string = document.body.innerHTML;
  //       document.body.innerHTML = printContent;
  //       window.focus();
  //       window.print();
  //       document.body.innerHTML = originalContent;
  //     }
  //   }
  // };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (['3', '4', '5'].includes(bmode)) {
      setExtraKms('0');
      setKms('0');
      setTotalExtraKms('0');
      setExtraHrs('0');
      setWaitChgs('0');
      setTotalWaiting('0');

      setEarly('0');
      setEarlyMorning('0');
      setLate('0');
      setOvernight('0');
    } else if (bmode === '2') {
      setTotalExtraKms((Number(extraKms) * Number(kms)).toString());

      setTotalWaiting((Number(extraHrs) * Number(waitChgs)).toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bmode]);

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="Bill Details" />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12} sx={{ p: 2 }}>
            {isLoading ? (
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  height: '80vh',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <div id="printData">
                <Box sx={{ p: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Box>
                        <Select
                          labelId="bmode"
                          id="bmode"
                          value={bmode}
                          onChange={e => setBmode(e.target.value)}
                          label="Billing Mode"
                        >
                          <MenuItem value="1">Tranfers</MenuItem>
                          <MenuItem value="2">Fixed Manual</MenuItem>
                          <MenuItem value="3">Manual</MenuItem>
                          <MenuItem value="4">General Transfers</MenuItem>
                          <MenuItem value="5">General Disposals</MenuItem>
                        </Select>
                      </Box>
                      <Box my={4} gap={6} alignItems="center" display="flex">
                        <Box>
                          <p>Date: {formatDate(date)}</p>
                        </Box>
                        <Box>
                          <p>Company: {company}</p>
                        </Box>
                        <Box>
                          <p>AC: {ac}</p>
                        </Box>
                        <Box>
                          <p>Rep Veh: {repveh}</p>
                        </Box>
                      </Box>
                      <Box my={4} gap={6} alignItems="center" display="flex">
                        <Box>
                          <p>Veh Type: {vehType}</p>
                        </Box>
                        <Box>
                          <p>Veh No: {vehNo}</p>
                        </Box>
                        <Box>
                          <p>Own code: {owncode}</p>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                      <Grid item container paddingBottom={2}>
                        <Grid item marginTop={2}>
                          <Typography>Sub Agent</Typography>
                        </Grid>
                        <Grid item paddingLeft={4}>
                          {subagentData && (
                            <Select
                              labelId="subagent"
                              id="subagent"
                              value={subagent}
                              variant="outlined"
                              onChange={e => setSubagent(e.target.value)}
                              label="Sub Agent"
                            >
                              {subagentData?.map((t: any) =>
                                t?.id && t?.name ? (
                                  <MenuItem key={t.id} value={t.name}>
                                    {t.name}
                                  </MenuItem>
                                ) : null
                              )}
                            </Select>
                          )}
                        </Grid>
                        <Grid item paddingLeft={4}>
                          <TextField
                            id="client"
                            value={client}
                            onChange={e => setClient(e.target.value)}
                            label="Client"
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                      {bmode === '1' && (
                        <Grid md={8} paddingBottom={2}>
                          <TextField
                            id="particulars"
                            value={particulars}
                            onChange={e => setParticulars(e.target.value)}
                            label="Transfer"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                          />
                        </Grid>
                      )}
                      <Box>
                        <Grid md={8}>
                          <TextField
                            id="particulars_type"
                            value={particulars_type}
                            onChange={e => setParticularsType(e.target.value)}
                            label="Particular"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                          />
                        </Grid>
                        <Grid marginTop={4} md={8} mb={4}>
                          <TextField
                            id="premark"
                            value={premark}
                            onChange={e => setPremark(e.target.value)}
                            label="Remarks"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                          />
                        </Grid>

                        {bmode === '1' && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <Box>
                              <Typography>Transfer</Typography>
                            </Box>
                            <Box>
                              <Select
                                labelId="transfer"
                                id="transfer"
                                value={transfer}
                                variant="outlined"
                                onChange={(e: any) => handleTransferChange(e)}
                                label="Transfer"
                                style={{ width: '130px' }}
                              >
                                (
                                <MenuItem value="" disabled>
                                  Select Transfer
                                </MenuItem>
                                {selectedTransferData?.map((t: any) => (
                                  <MenuItem key={t.id} value={t.id}>
                                    {t.type}
                                  </MenuItem>
                                ))}
                                )
                              </Select>
                            </Box>
                          </Box>
                        )}

                        {bmode === '3' && (
                          <Box
                            my={4}
                            gap={4}
                            alignItems="center"
                            display="flex"
                          >
                            <Box>
                              <Select
                                labelId="mintype"
                                id="minType"
                                value={minType}
                                onChange={e => setMinType(e.target.value)}
                                label="Min Type"
                              >
                                <MenuItem value="1">8 HRs / 100 KMs</MenuItem>
                                <MenuItem value="2">8 HRs / 80 KMs</MenuItem>
                              </Select>
                            </Box>
                          </Box>
                        )}
                        <Box style={{ marginBottom: '20px' }}>
                          <TextField
                            id="rate"
                            value={rate}
                            onChange={e => setRate(e.target.value)}
                            label="Amount"
                            variant="outlined"
                          />
                        </Box>
                        {/* {bmode === '2' && (
                          <>
                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="extraKms"
                                  value={extraKms}
                                  onChange={(e: any) => {
                                    setExtraKms(e.target.value);
                                    setTotalExtraKms(
                                      (
                                        Number(e.target.value) * Number(kms)
                                      ).toString()
                                    );
                                  }}
                                  label="Extra Kms"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="kms"
                                  value={kms}
                                  onChange={e => setKms(e.target.value)}
                                  label="Rate"
                                  variant="outlined"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  id="totalExtraKms"
                                  value={totalExtraKms}
                                  onChange={e =>
                                    setTotalExtraKms(e.target.value)
                                  }
                                  label="Total Extra Kms"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>

                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="extraHrs"
                                  value={extraHrs}
                                  // onChange={e => setExtraHrs(e.target.value)}
                                  onChange={(e: any) => {
                                    setExtraHrs(e.target.value);
                                    setTotalWaiting(
                                      (
                                        Number(e.target.value) *
                                        Number(waitChgs)
                                      ).toString()
                                    );
                                  }}
                                  label="Extra Hours"
                                  variant="outlined"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  id="waitChgs"
                                  value={waitChgs}
                                  onChange={e => setWaitChgs(e.target.value)}
                                  label="Waiting Charges"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="totalWaiting"
                                  value={totalWaiting}
                                  onChange={e =>
                                    setTotalWaiting(e.target.value)
                                  }
                                  label="Total Waiting"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>
                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="early"
                                  value={early}
                                  onChange={e => setEarly(e.target.value)}
                                  label="Early"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="earlyMorning"
                                  value={earlyMorning}
                                  onChange={e =>
                                    setEarlyMorning(e.target.value)
                                  }
                                  label="Early Morning"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>

                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="late"
                                  value={late}
                                  onChange={e => setLate(e.target.value)}
                                  label="Late"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="overnight"
                                  value={overnight}
                                  onChange={e => setOvernight(e.target.value)}
                                  label="Overnight"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>
                          </>
                        )} */}

                        {(bmode === '2' ||
                          bmode === '3' ||
                          bmode === '4' ||
                          bmode === '5') && (
                          <>
                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="extraKms"
                                  value={extraKms}
                                  onChange={(e: any) => {
                                    setExtraKms(e.target.value);
                                    setTotalExtraKms(
                                      (
                                        Number(e.target.value) * Number(kms)
                                      ).toString()
                                    );
                                  }}
                                  label="Extra Kms"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="kms"
                                  value={kms}
                                  onChange={e => {
                                    setKms(e.target.value);
                                    setTotalExtraKms(
                                      (
                                        Number(e.target.value) *
                                        Number(extraKms)
                                      ).toString()
                                    );
                                  }}
                                  label="Rate"
                                  variant="outlined"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  id="totalExtraKms"
                                  value={totalExtraKms}
                                  onChange={e =>
                                    setTotalExtraKms(e.target.value)
                                  }
                                  label="Total Extra Kms"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>

                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="extraHrs"
                                  value={extraHrs}
                                  // onChange={e => setExtraHrs(e.target.value)}
                                  onChange={(e: any) => {
                                    setExtraHrs(e.target.value);
                                    setTotalWaiting(
                                      (
                                        Number(e.target.value) *
                                        Number(waitChgs)
                                      ).toString()
                                    );
                                  }}
                                  label="Extra Hours"
                                  variant="outlined"
                                />
                              </Box>

                              <Box>
                                <TextField
                                  id="waitChgs"
                                  value={waitChgs}
                                  onChange={e => {
                                    setWaitChgs(e.target.value);
                                    setTotalWaiting(
                                      (
                                        Number(e.target.value) *
                                        Number(extraHrs)
                                      ).toString()
                                    );
                                  }}
                                  label="Waiting Charges"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="totalWaiting"
                                  value={totalWaiting}
                                  onChange={e =>
                                    setTotalWaiting(e.target.value)
                                  }
                                  label="Total Waiting"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>

                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="early"
                                  value={early}
                                  onChange={e => setEarly(e.target.value)}
                                  label="Early"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="earlyMorning"
                                  value={earlyMorning}
                                  onChange={e =>
                                    setEarlyMorning(e.target.value)
                                  }
                                  label="Early Morning"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>

                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="late"
                                  value={late}
                                  onChange={e => setLate(e.target.value)}
                                  label="Late"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="overnight"
                                  value={overnight}
                                  onChange={e => setOvernight(e.target.value)}
                                  label="Overnight"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>
                          </>
                        )}

                        {/* {(bmode === '3' || bmode === '4' || bmode === '5') && (
                          <>
                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="early"
                                  value={0}
                                  onChange={e => setEarly(e.target.value)}
                                  label="Early"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="earlyMorning"
                                  value={0}
                                  onChange={e =>
                                    setEarlyMorning(e.target.value)
                                  }
                                  label="Early Morning"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>

                            <Box
                              my={4}
                              gap={4}
                              alignItems="center"
                              display="flex"
                            >
                              <Box>
                                <TextField
                                  id="late"
                                  value={0}
                                  onChange={e => setLate(e.target.value)}
                                  label="Late"
                                  variant="outlined"
                                />
                              </Box>
                              <Box>
                                <TextField
                                  id="overnight"
                                  value={0}
                                  onChange={e => setOvernight(e.target.value)}
                                  label="Overnight"
                                  variant="outlined"
                                />
                              </Box>
                            </Box>
                          </>
                        )} */}

                        <FormGroup row>
                          <FormControlLabel
                            key="earlyCheckbox"
                            control={
                              <Checkbox
                                checked={earlyCheckbox}
                                onChange={e =>
                                  setEarlyCheckbox(e.target.checked)
                                }
                              />
                            }
                            label="Early"
                          />
                          <FormControlLabel
                            key="earlyMorningCheckbox"
                            control={
                              <Checkbox
                                checked={earlyMorningCheckbox}
                                onChange={e =>
                                  setEarlyMorningCheckbox(e.target.checked)
                                }
                              />
                            }
                            label="Early Morning"
                          />
                          <FormControlLabel
                            key="late"
                            control={
                              <Checkbox
                                checked={lateCheckbox}
                                onChange={e =>
                                  setLateCheckbox(e.target.checked)
                                }
                              />
                            }
                            label="Late"
                          />
                          <FormControlLabel
                            key="nite"
                            control={
                              <Checkbox
                                checked={niteCheckbox}
                                onChange={e =>
                                  setNiteCheckbox(e.target.checked)
                                }
                              />
                            }
                            label="O.Nite"
                          />
                        </FormGroup>

                        <Box my={4} gap={4} alignItems="center" display="flex">
                          {/* <FormGroup row> */}
                          <p>Extras</p>
                          {extrasData?.map((e: any, i: any) => (
                            <FormControlLabel
                              key={e.id}
                              control={
                                <Checkbox
                                  id={e.id}
                                  checked={extraCheckbox[i]}
                                  onChange={ev => {
                                    const updatedExtraCheckbox: any = [];
                                    if (extraCheckbox) {
                                      extraCheckbox?.map(
                                        (ec: any, index: number) => {
                                          if (index === i) {
                                            updatedExtraCheckbox.push(
                                              ev.target.checked
                                            );
                                          } else {
                                            updatedExtraCheckbox.push(ec);
                                          }
                                          return null;
                                        }
                                      );
                                      setExtraCheckbox(updatedExtraCheckbox);
                                    }
                                  }}
                                />
                              }
                              label={e.code}
                            />
                          ))}
                          {/* </FormGroup> */}
                          <TextField
                            id="extrasToll"
                            value={extrasToll}
                            onChange={e => setExtrasToll(e.target.value)}
                            label="Extras Toll"
                            variant="outlined"
                          />
                        </Box>

                        <Box my={4} gap={4} alignItems="center" display="flex">
                          <TextField
                            id="extraComment"
                            value={extraComment}
                            onChange={e => setExtraComment(e.target.value)}
                            label="Extras Comment"
                            variant="outlined"
                          />
                        </Box>

                        <Grid container paddingBottom={2}>
                          <Grid item md={6}>
                            <TextField
                              id="additional charges 1"
                              value={additionalChargs1}
                              onChange={e =>
                                setAdditionalChargs1(e.target.value)
                              }
                              label="Additional Charges 1 Particulars"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid paddingLeft={2}>
                            <TextField
                              id="additional amount 1"
                              value={additionalAmount1}
                              onChange={e =>
                                setAdditionalAmount1(e.target.value)
                              }
                              label="Amount 1"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>
                        <Grid container paddingBottom={2}>
                          <Grid item md={6}>
                            <TextField
                              id="additional charges 2"
                              value={additionalChargs2}
                              onChange={e =>
                                setAdditionalChargs2(e.target.value)
                              }
                              label="Additional Charges 2 Particulars"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid paddingLeft={2}>
                            <TextField
                              id="additional amount 2"
                              value={additionalAmount2}
                              onChange={e =>
                                setAdditionalAmount2(e.target.value)
                              }
                              label="Amount 2"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>

                        <Grid container paddingBottom={2}>
                          <Grid item md={6}>
                            <TextField
                              id="additional charges 3"
                              value={additionalChargs3}
                              onChange={e =>
                                setAdditionalChargs3(e.target.value)
                              }
                              label="Additional Charges 3 Particulars"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid paddingLeft={2}>
                            <TextField
                              id="additional amount 3"
                              value={additionalAmount3}
                              onChange={e =>
                                setAdditionalAmount3(e.target.value)
                              }
                              label="Amount 3"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>

                        <Grid container paddingBottom={2}>
                          <Grid item md={6}>
                            <TextField
                              id="additional charges 4"
                              value={additionalChargs4}
                              onChange={e =>
                                setAdditionalChargs4(e.target.value)
                              }
                              label="Additional Charges 4 Particulars"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                          <Grid paddingLeft={2}>
                            <TextField
                              id="additional amount 4"
                              value={additionalAmount4}
                              onChange={e =>
                                setAdditionalAmount4(e.target.value)
                              }
                              label="Amount 4"
                              variant="outlined"
                            />
                          </Grid>
                        </Grid>

                        <Box paddingTop={4} marginTop={4}>
                          <Button
                            variant="contained"
                            onClick={updateBillDetails}
                          >
                            Update
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            )}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default BillRegisterEdit;
