import { useEffect, useState } from 'react';

import { format, lastDayOfMonth, startOfMonth } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper, TextField } from '@mui/material';

import MaterialTable from '@material-table/core';

import DeleteDialog from '@components/Dialog/DeleteDialog';
import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useDeleteBillReg, useGetBillRegFiltered } from '../../hooks/bill';
import { useReadContracts } from '../../hooks/contract';
import { useReadHireCharts } from '../../hooks/hirechart';

const BillRegister = () => {
  const { enqueueSnackbar } = useSnackbar();
  // const { data: billregs, isLoading, refetch } = useReadBillRegs();
  const [startDate, setStartDate] = useState<any>(
    format(startOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [endDate, setEndDate] = useState<any>(
    format(lastDayOfMonth(new Date()), 'yyyy-MM-dd')
  );
  const [search, setSearch] = useState(false);

  const { data, isLoading, refetch } = useGetBillRegFiltered(
    {
      startDate,
      endDate,
    },
    search
  ) as any;

  const { data: hirecharts, isLoading: isLoadingHires } = useReadHireCharts();
  const { data: contracts } = useReadContracts();

  const { mutate: deleteBillRegMutate } = useDeleteBillReg();

  const [selectedBill, setSelectedBill] = useState<any>('');

  const billregs = data
    ?.filter((d: any) => d.status === '1')
    .map((br: any, index: number) => {
      const hc: any = hirecharts?.find(h => h.billno === br.id);
      br.company = hc?.company;
      br.sr = index + 1;
      return br;
    });

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Bill - nunes';
  }, []);

  const handleDetails = (rowData: any) => {
    if (rowData) {
      setSelectedBill(rowData);
      if (rowData.type === '1') navigate(`/billRegister/${rowData.id}`);
      if (rowData.type === '2') {
        const selectedContract = contracts?.filter(
          c => c.billno === rowData.id
        );
        if (selectedContract && selectedContract.length)
          navigate(`/contractDetails/${selectedContract[0].id}`);
      }
    }
  };

  const handleEdit = (rowData: any) => {
    setSelectedBill(rowData);
    if (rowData.type === '1') navigate(`/billRegister/${rowData.id}`);
    if (rowData.type === '2') {
      const selectedContract = contracts?.filter(c => c.billno === rowData.id);
      if (selectedContract && selectedContract.length)
        navigate(`/contractDetails/${selectedContract[0].id}`);
    }
  };

  const handleDelete = (rowData: any) => {
    setSelectedBill(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (selectedBill) {
      deleteBillRegMutate(
        { id: selectedBill?.id },
        {
          onSuccess: () => {
            enqueueSnackbar('Content Deleted', { variant: 'success' });
            refetch();
          },
          onError: () => {
            enqueueSnackbar('Failed to Update Content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const columns: any = [
    {
      title: 'S.No',
      field: 'sr',
      sorting: true,
      width: '5%',
    },
    {
      title: 'Date',
      field: 'date',
      render: (rowData: any) => `${formatDate(rowData.date)}`,
    },
    {
      title: 'Bill No',
      field: 'billnum',
      defaultSort: 'desc',
      render: (rowData: any) => `${rowData.billnum} `,
    },
    {
      title: 'Type',
      field: 'type',
      render: (rowData: any) =>
        `${rowData.type === '1' ? 'Hire' : 'Contract'} `,
    },
    {
      title: 'Company',
      field: 'company',
      render: (rowData: any) => `${rowData.company || ''} `,
    },
    {
      title: 'Total',
      field: 'total',
      render: (rowData: any) => `${rowData.total} `,
    },
    {
      title: 'Payment',
      field: 'paid',
      render: (rowData: any) => `${rowData.paid === '0' ? 'Unpaid' : 'Paid'} `,
    },
    {
      title: 'Remarks',
      field: 'premark',
      render: (rowData: any) => `${rowData.premark} `,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="Register Bills" />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid container direction="row" gap={2} paddingLeft={2}>
            <Grid item>
              <TextField
                id="startDate"
                label="Date"
                type="date"
                variant="outlined"
                margin="normal"
                value={startDate}
                onChange={e => setStartDate(e.target.value)}
              />
            </Grid>
            <Grid item>
              <TextField
                id="endDate"
                label="Date"
                type="date"
                variant="outlined"
                margin="normal"
                value={endDate}
                onChange={e => setEndDate(e.target.value)}
              />
            </Grid>
            <Grid item paddingTop={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setSearch(!search)}
              >
                Search
              </Button>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={billregs || []}
              isLoading={isLoading && isLoadingHires}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
                Toolbar: () => <div style={{ margin: '10px' }}> </div>,
              }}
              onRowClick={(_, rowData) => handleDetails(rowData)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Bill',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Bill',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default BillRegister;
