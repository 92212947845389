import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
// import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';

import MaterialTable from '@material-table/core';

import PageTitle from '@components/PageTitle';

import { formatDate } from '../../helpers/dateFormat';
import { useAddAdditional } from '../../hooks/additional';
import {
  // useGetBillRegByLastCreated,
  useReadBills,
  useUpdateBillByHid,
  useUpdateBillRegByBillNo,
} from '../../hooks/bill';
import { useFilterHireChart } from '../../hooks/hirechart';

const DirectBillDetail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate: updateBillRegByBillNoMutate } = useUpdateBillRegByBillNo();
  const { mutate: addAdditionalMutate } = useAddAdditional();
  const { mutate: updateBillByHidMutate } = useUpdateBillByHid();

  const [discountAmount, setDiscountAmount] = useState('0');
  const [additionalAmount, setAdditionalAmount] = useState('0');
  const [additionalParticular, setAdditionalParticular] = useState('0');

  const [hirecharts, setHirecharts] = useState<any>();
  const [selectedHirecharts, setSelectedHirecharts] = useState<any>([]);

  // const [bmode, setBmode] = useState('3');

  const { id } = useParams();
  const { data: billList, isLoading, isError } = useReadBills();

  // const { data: lastCreatedBillReg } = useGetBillRegByLastCreated();

  // const { data } = useReadHireCharts();
  // const { data } = useGetHirechartsFiltered();
  const data = JSON.parse(localStorage.getItem('hirechartDetails') as string);

  const { mutate: filterHireChartMutate, isPending } = useFilterHireChart();

  useEffect(() => {
    filterHireChartMutate(
      {},
      {
        onSuccess: (res: any) => {
          const modifiedData = data?.map((h: any) => {
            const bill: any = billList?.find(
              b => Number(b.hid) === Number(h.id)
            );

            const hirechart = res?.data?.find((hc: any) => hc.id === h.id);
            return {
              id: h.id,
              hirechart,
              bill,
            };
          });

          console.log('444', modifiedData);

          if (modifiedData && modifiedData.length && modifiedData[0].bill)
            // setBmode(modifiedData[0].bill.mode);

            setHirecharts(modifiedData);
        },
      }
      // }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billList]);

  // useEffect(() => {
  //   if (data && billList) {
  //     // eslint-disable-next-line
  //     // data = data?.filter((h:any) => h.billno !== '');

  //     // // eslint-disable-next-line
  //     // data = data?.filter((h:any) => Number(h.billno) === Number(id));

  //     // eslint-disable-next-line
  //     console.log('billList', billList);
  //     console.log('data', data);

  //     const modifiedData = data?.map((h: any) => {
  //       const bill: any = billList?.find(b => Number(b.hid) === Number(h.id));

  //       return {
  //         id: h.id,
  //         hirechart: h,
  //         bill,
  //       };
  //     });

  //     console.log('444', modifiedData);

  //     if (modifiedData && modifiedData.length && modifiedData[0].bill)
  //       // setBmode(modifiedData[0].bill.mode);

  //       setHirecharts(modifiedData);
  //   }
  //   // eslint-disable-next-line
  // }, [billList]);

  useEffect(() => {
    hirecharts?.map((h: any) => {
      updateBillByHidMutate({
        hid: h.id,
        // mode: bmode,
      });

      return null;
    });
    // eslint-disable-next-line
  }, []);

  const navigate = useNavigate();

  if (isError) {
    return <div>Error fetching Bill</div>;
  }

  // const handleDetails = (rowData: any) => {
  //   if (rowData) {
  //     navigate(`/directBill/${rowData.hid}/view`);
  //   }
  // };

  const setSelectedRows = (rowsData: any) => {
    setSelectedHirecharts(rowsData);
  };

  const handleMakeBill = () => {
    let hirechartToDisplay: any = [];
    hirechartToDisplay = selectedHirecharts.map((h: any) =>
      hirecharts?.find((hc: any) => hc.id === h.hirechart.id)
    );

    // let billCount = 101;
    // if (lastCreatedBillReg) {
    //   billCount = Number(lastCreatedBillReg.id) + 1;
    // }
    // const currentYear = new Date().getFullYear().toString().slice(-2);
    // const lastYear = Number(currentYear) - 1;
    // const billNo = `JPN${lastYear}${currentYear}${billCount}`;
    // if (hirechartToDisplay && hirechartToDisplay.length) {
    //   hirechartToDisplay[0].billNo = billNo;
    // }
    localStorage.setItem('hirecharts', JSON.stringify(hirechartToDisplay));
    navigate(`/bill/view`);
  };

  const handleEdit = (rowData: any) => {
    if (rowData) {
      navigate(`/newBill/${rowData.id}/edit`);
    }
  };

  // const updateContractDetails = () => {
  //   // updateBillRegMutate(
  //   //   { premark, paid, id: contractDetails?.billno },
  //   //   {
  //   //     onSuccess: data => {},
  //   //     onError: error => {},
  //   //   }
  //   // );
  // };

  const updateAdditionalDetails = () => {
    addAdditionalMutate(
      {
        billNo: id,
        particular: additionalParticular,
        amount: additionalAmount,
      },
      {
        onSuccess: () => {
          enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update Content', { variant: 'error' });
        },
      }
    );
  };

  const updateDiscountDetails = () => {
    updateBillRegByBillNoMutate(
      { billNum: id, discount: discountAmount },
      {
        onSuccess: () => {
          enqueueSnackbar('Content Updated', { variant: 'info' });
        },
        onError: () => {
          enqueueSnackbar('Failed to Update year', { variant: 'error' });
        },
      }
    );
  };

  //   proformaList?.map((p: any) => {
  //     let hc: any = hirechartList?.find(h => h.id === p.hid);

  //     if (!hc) {
  //       hc = contractList?.find(h => h.id === p.hid);
  //     }
  //     p.date = hc?.date;
  //     p.vtype = hc?.vtype;
  //     p.vehno = hc?.vno;
  //     p.particular = hc?.particulars;
  //     return p;
  //   });

  const columns: any = [
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: (rowData: any) => `${formatDate(rowData?.hirechart?.date)} `,
    },
    {
      title: 'Company',
      field: 'company',
      render: (rowData: any) => `${rowData?.hirechart?.company} `,
    },
    {
      title: 'Vehicle Type',
      field: 'vtype',
      render: (rowData: any) => `${rowData?.hirechart?.vtype} `,
    },
    {
      title: 'Vehicle No',
      field: 'vehicleno',
      render: (rowData: any) => `${rowData?.hirechart?.vno || ''} `,
    },
    {
      title: 'Particular',
      render: (rowData: any) =>
        `${rowData.hirechart?.particulars}/${rowData.hirechart?.particulars_type}`,
    },
    {
      title: '8 Hrs / 100 KMs',
      field: 'kms',
      render: (rowData: any) => `${rowData?.bill?.rate || 0} `,
    },
    {
      title: 'Ex Kms',
      field: 'xkms',
      render: (rowData: any) => `${rowData?.bill?.xkms || 0} `,
    },
    {
      title: 'Rate',
      field: 'rate',
      render: (rowData: any) => `${rowData?.bill?.kms || 0} `,
    },
    {
      title: 'Tot',
      field: 'tot1',
      render: (rowData: any) => `${rowData?.bill?.tot1 || 0} `,
    },
    {
      title: 'Ex HRs',
      field: 'xhrs',
      render: (rowData: any) => `${rowData?.bill?.xhrs || 0} `,
    },
    {
      title: 'Wait Ch',
      field: 'wchr',
      render: (rowData: any) => `${rowData?.bill?.wchr || 0} `,
    },
    {
      title: 'Tot',
      field: 'tot2',
      render: (rowData: any) => `${rowData?.bill?.tot2 || 0} `,
    },
    {
      title: 'Early',
      field: 'early',
      render: (rowData: any) => `${rowData?.bill?.early || 0} `,
    },
    {
      title: 'Early Morning',
      field: 'early_morning',
      render: (rowData: any) => `${rowData?.bill?.early_morning || 0} `,
    },
    {
      title: 'Late',
      field: 'late',
      render: (rowData: any) => `${rowData?.bill?.late || 0} `,
    },
    {
      title: 'Nite',
      field: 'onite',
      render: (rowData: any) => `${rowData?.bill?.onite || 0} `,
    },
    {
      title: 'Extra',
      field: 'toll',
      render: (rowData: any) => `${rowData?.bill?.toll || 0} `,
    },
    {
      title: 'Total',
      field: 'tot3',
      render: (rowData: any) => `${rowData?.bill?.tot3 || 0} `,
    },
    {
      title: 'hid',
      field: 'hid',
      render: (rowData: any) => `${rowData?.bill?.hid} `,
      hidden: true,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle text="New Bill" />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid
            container
            item
            xs={4}
            sm={2}
            alignSelf="flex-start"
            direction="row"
            paddingTop={4}
            paddingLeft={2}
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleMakeBill}
              >
                Make Bill
              </Button>
            </Grid>
            {/* <Grid paddingTop={2}>
              <Select
                labelId="bmode"
                id="bmode"
                value={bmode}
                onChange={e => setBmode(e.target.value)}
                label="Billing Mode"
              >
                <MenuItem value="1">Tranfers</MenuItem>
                <MenuItem value="2">Fixed Manual</MenuItem>
                <MenuItem value="3">Manual</MenuItem>
                <MenuItem value="4">General Transfers</MenuItem>
                <MenuItem value="5">General Disposals</MenuItem>
              </Select>
            </Grid> */}
          </Grid>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={hirecharts || []}
              isLoading={isLoading || isPending}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
                selection: true,
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
                Toolbar: () => <div style={{ margin: '10px' }}> </div>,
              }}
              // onRowClick={(_, rowData) => handleDetails(rowData)}
              onSelectionChange={rows => setSelectedRows(rows)}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit',
                  position: 'row',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
              ]}
            />
          </Grid>

          <Box>
            <Box sx={{ mt: 2, mb: 2 }} marginLeft={2}>
              <Typography style={{ marginLeft: '4px' }} variant="h6">
                Add Additional Charges
              </Typography>
              <TextField
                id="additionalParticular"
                value={additionalParticular}
                onChange={e => setAdditionalParticular(e.target.value)}
                label="Particular"
                variant="outlined"
                style={{ marginLeft: '12px' }}
              />
              <TextField
                id="additionalAmount"
                value={additionalAmount}
                onChange={e => setAdditionalAmount(e.target.value)}
                label="Amount"
                variant="outlined"
                style={{ marginLeft: '12px' }}
              />
              <Button
                style={{ marginLeft: '12px' }}
                variant="contained"
                onClick={updateAdditionalDetails}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Box>
            <Box sx={{ mt: 2, mb: 2 }} marginLeft={2}>
              <Typography style={{ marginLeft: '4px' }} variant="h6">
                Discount
              </Typography>
              <TextField
                id="amount"
                value={discountAmount}
                onChange={e => setDiscountAmount(e.target.value)}
                label="Amount"
                variant="outlined"
                style={{ marginLeft: '12px' }}
              />
              <Button
                style={{ marginLeft: '12px' }}
                variant="contained"
                onClick={updateDiscountDetails}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DirectBillDetail;
