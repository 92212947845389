import { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddSubAgentRates,
  useDeleteSubAgentRates,
  useEditSubAgentRates,
  useGetSubAgentRates,
  useReadSubAgent,
} from '../../../../hooks/controlpanel/subagent';
import {
  ISubAgentRates,
  ISubAgentTariff,
} from '../../../../types/controlpanel/subagent';
import AddSubAgentRates from './AddSubAgentRates';
import EditSubAgentRates from './EditSubAgentRates';

const SubAgentTransferRates = () => {
  const [srates, setSrates] = useState<ISubAgentRates | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [rateListing, setRateListing] = useState<any[]>([]);

  const params = useParams<{ id: string }>();
  const { data: subAgentDetails, isLoading } = useReadSubAgent({
    id: params?.id ?? '',
  });

  const { data: fetchedRateListing, refetch: refetchRates } =
    useGetSubAgentRates({
      subAgentName: subAgentDetails?.name ?? '',
    });
  const { mutate: addratesMutate } = useAddSubAgentRates();
  const { mutate: editratesMutate } = useEditSubAgentRates();
  const { mutate: deleteratesMutate } = useDeleteSubAgentRates();

  useEffect(() => {
    if (fetchedRateListing) {
      const updatedListing = fetchedRateListing.map(
        (item: any, index: number) => ({
          ...item,
          sr: index + 1,
        })
      );
      setRateListing(updatedListing);
    }
  }, [fetchedRateListing]);

  const handleEdit = (rowData: any) => {
    setSrates(rowData);
    setDefaultvalue({
      vid: rowData?.vid,
      cid: subAgentDetails?.name,
      mintype: rowData?.mintype,
      initial: rowData?.initial,
      info: rowData?.info,
      xkm: rowData?.xkm,
      xhr: rowData?.xhr,
      late: rowData?.late,
      onite: rowData?.onite,
      early: rowData?.early,
      early_morning: rowData?.early_morning,
    });
    setEditDialogOpen(true);
  };

  const handleDelete = (rowData: any) => {
    setSrates(rowData);
    setDeleteDialogOpen(true);
  };

  const handleCloseEditDialog = (action: any) => {
    if (srates && action && action?.action) {
      editratesMutate(
        {
          ...action?.formData,
          id: srates.id,
          cid: subAgentDetails?.name ?? '',
        },
        {
          onSuccess: () => {
            refetchRates();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (srates) {
      deleteratesMutate(
        { id: +srates.id },
        {
          onSuccess: () => {
            refetchRates();
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addratesMutate(
        {
          ...action?.formData,
          cid: subAgentDetails?.name ?? '',
        },
        {
          onSuccess: () => {
            refetchRates();
            setAddDialogOpen(false);
          },
          onError: () => {
            enqueueSnackbar('Failed to Add content', { variant: 'error' });
          },
        }
      );
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<ISubAgentTariff>[] = [
    {
      title: 'S.No',
      field: 'sr',
      sorting: true,
      width: '5%',
    },
    {
      title: 'Vehicle Type',
      field: 'vid',
    },
    {
      title: 'Minimum',
      field: 'mintype',
    },
    {
      title: 'Extra Km',
      field: 'xkm',
    },
    {
      title: 'Extra Hr',
      field: 'xhr',
    },
    {
      title: 'Early',
      field: 'early',
    },
    {
      title: 'Extra Morning',
      field: 'early_morning',
    },
    {
      title: 'Late',
      field: 'late',
    },
    {
      title: 'Overnight',
      field: 'onite',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Sightseeing / Disposal Rates"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Rates
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={rateListing || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Data',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Data',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddSubAgentRates}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditSubAgentRates}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default SubAgentTransferRates;
