import { useEffect, useState } from 'react';

import { enqueueSnackbar } from 'notistack';

import AddIcon from '@mui/icons-material/Add';
// import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Grid, Paper } from '@mui/material';

import MaterialTable, { Column } from '@material-table/core';

import AddDialog from '@components/Dialog/AddDialog';
import DeleteDialog from '@components/Dialog/DeleteDialog';
import EditDialog from '@components/Dialog/EditDialog';
import PageTitle from '@components/PageTitle';

import {
  useAddGeneralRates,
  useDeleteGeneralRates,
  useEditGeneralRates,
  useReadGeneralRates,
} from '../../../../hooks/controlpanel/genraltariff';
import { IGeneralRates } from '../../../../types/controlpanel/generaltariff';
import AddGeneralRates from './AddGeneralRates';
import EditGeneralRates from './EditGeneralRates';

const GeneralTransferRates = () => {
  const { data: rates, isLoading, refetch } = useReadGeneralRates();

  const [localrates, setLocalRates] = useState<IGeneralRates[]>([]);
  const [srates, setSrates] = useState<IGeneralRates | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [defaultvalue, setDefaultvalue] = useState<any>();
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const { mutate: addratesMutate } = useAddGeneralRates();
  const { mutate: editratesMutate } = useEditGeneralRates();
  const { mutate: deleteratesMutate } = useDeleteGeneralRates();

  useEffect(() => {
    if (rates) {
      const ratesWithSr = rates.map((ratesItems, index) => ({
        ...ratesItems,
        sr: index + 1,
      }));
      setLocalRates(ratesWithSr);
    }
  }, [rates]);

  const handleEdit = (rowData: any) => {
    setSrates(rowData);
    setDefaultvalue({
      vid: rowData?.vid,
      intial: rowData?.intial,
      info: rowData?.info,
      xkm: rowData?.xkm,
      xhr: rowData?.xhr,
      late: rowData?.late,
      onite: rowData?.onite,
      early: rowData?.early,
      early_morning: rowData?.early_morning,
    });
    setEditDialogOpen(true);
  };

  // const handleDelete = (rowData: any) => {
  //   setSrates(rowData);
  //   setDeleteDialogOpen(true);
  //   enqueueSnackbar('Delete Modal', { variant: 'warning' });
  // };

  const handleCloseEditDialog = (action: any) => {
    if (srates && action && action?.action) {
      editratesMutate(
        { ...action?.formData, id: srates.id },
        {
          onSuccess: () => {
            refetch();
            setEditDialogOpen(false);
            enqueueSnackbar('Content Updated', { variant: 'info' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Update content', { variant: 'error' });
          },
        }
      );
    } else {
      setEditDialogOpen(false);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleConfirmDelete = () => {
    if (srates) {
      deleteratesMutate(
        { id: +srates.id },
        {
          onSuccess: () => {
            setLocalRates(prevRates =>
              prevRates.filter(c => c.id !== srates.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete content', { variant: 'error' });
          },
        }
      );
    }
    setDeleteDialogOpen(false);
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = (action: any) => {
    if (action && action?.action) {
      addratesMutate(action?.formData, {
        onSuccess: () => {
          refetch();
          setAddDialogOpen(false);
        },
        onError: (err: any) => {
          if (err?.response?.data?.message === 'General rate already exists') {
            enqueueSnackbar('General rate already exists', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Failed to Add content', { variant: 'error' });
          }
        },
      });
    } else {
      setAddDialogOpen(false);
    }
  };

  const columns: Column<IGeneralRates>[] = [
    {
      title: 'S.No',
      field: 'sr',
      sorting: true,
      width: '5%',
    },
    {
      title: 'Vehicle Type',
      field: 'vid',
    },
    {
      title: 'Minimum',
      field: 'initial',
    },
    {
      title: 'Extra Km',
      field: 'xkm',
    },
    {
      title: 'Extra Hr',
      field: 'xhr',
    },
    {
      title: 'Early',
      field: 'early',
    },
    {
      title: 'Extra Morning',
      field: 'early_morning',
    },
    {
      title: 'Late',
      field: 'late',
    },
    {
      title: 'Overnight',
      field: 'onite',
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      sx={{
        height: '100%',
        padding: { xs: '0.5rem', sm: '1rem' },
      }}
    >
      <Grid item xs={2} sm={1} sx={{ paddingY: '1rem' }}>
        <PageTitle
          text="Sightseeing / Disposal Rates"
          primaryAction={
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDialogOpen}
            >
              <AddIcon sx={{ mr: 1 }} />
              Add Rates
            </Button>
          }
        />
      </Grid>

      <Grid item xs container spacing={2}>
        <Paper elevation={2} sx={{ width: '100%' }}>
          <Grid item xs={12}>
            <MaterialTable
              title=""
              columns={columns}
              data={localrates || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Data',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                // {
                //   icon: DeleteIcon,
                //   tooltip: 'Delete Data',
                //   onClick: (event, rowData) => handleDelete(rowData),
                // },
              ]}
            />
          </Grid>
        </Paper>
      </Grid>

      <AddDialog
        open={addDialogOpen}
        handleClose={handleAddDialogClose}
        ContentComponent={AddGeneralRates}
      />

      <EditDialog
        open={editDialogOpen}
        handleClose={handleCloseEditDialog}
        ContentComponent={EditGeneralRates}
        billMade={false}
        defaultvalue={defaultvalue}
      />

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </Grid>
  );
};

export default GeneralTransferRates;
