import { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useNavigate, useParams } from 'react-router-dom';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Card,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';

import MaterialTable from '@material-table/core';

import DeleteDialog from '@components/Dialog/DeleteDialog';

import { formatDate } from '../../helpers/dateFormat';
import { useReadCompanies } from '../../hooks/company';
// import { useReadContracts } from '../../hooks/contract';
import { useReadHireCharts } from '../../hooks/hirechart';
import { useDeleteProforma, useReadProformas } from '../../hooks/proforma';

const ProformaDetail = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [contract, setContract] = useState<any>(null);

  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [taxable, setTaxable] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);

  const [updatedHirechartList, setUpdatedHirechartList] = useState<any>([]);

  const { id } = useParams();

  const { data: proformaList } = useReadProformas();

  const { mutate: deleteProformaMutate } = useDeleteProforma();

  // eslint-disable-next-line
  let {
    data: hirechartList,
    // eslint-disable-next-line
    isLoading,
    // eslint-disable-next-line
    isError,
    // eslint-disable-next-line
    // refetch,
  } = useReadHireCharts();

  const { data: companies } = useReadCompanies();

  // const {
  //   data: contractList,
  //   // isLoading,
  //   // isError,
  // } = useReadContracts();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Proforma - nunes';
  }, []);

  if (isError) {
    return <div>Error fetching Proforma</div>;
  }

  const handleEdit = (rowData: any) => {
    if (rowData) {
      navigate(`/edit-proforma/${rowData.id}`);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDelete = (rowData: any) => {
    setContract(rowData);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    if (contract) {
      deleteProformaMutate(
        { id: contract?.id },
        {
          onSuccess: () => {
            setUpdatedHirechartList((prev: any) =>
              prev.filter((c: any) => c.id !== contract.id)
            );
            enqueueSnackbar('Content Deleted', { variant: 'success' });
          },
          onError: () => {
            enqueueSnackbar('Failed to Delete Content', {
              variant: 'error',
            });
          },
        }
      );
    }
    console.log('333');
    setDeleteDialogOpen(false);
  };

  // proformaList = proformaList?.map((p: any) => {
  //   let hc: any = hirechartList?.find(h => h.id === p.hid);

  //   if (!hc) {
  //     hc = contractList?.find(h => h.id === p.hid);
  //   }
  //   p.date = hc?.date;
  //   p.vtype = hc?.vtype;
  //   p.vehno = hc?.vno;
  //   p.particular = hc?.particulars;
  //   return p;
  // });

  // eslint-disable-next-line
  useEffect(() => {
    if (hirechartList && proformaList) {
      // eslint-disable-next-line
      hirechartList = hirechartList?.filter(
        h => Number(h.proformano) === Number(id)
      );
      let totalVal = 0;
      let discountVal = 0;
      // eslint-disable-next-line
      const updatedHirechartListArray: any = hirechartList
        ?.map((h: any) => {
          const proforma = proformaList?.find(
            p => Number(p.hid) === Number(h.id)
          );

          if (proforma) {
            totalVal += Number(proforma?.tot3 || 0);
            discountVal = 0;
            return {
              ...proforma,
              date: h?.date,
              vtype: h?.vtype,
              vehno: h?.vno,
              particular: h?.particulars,
              proformano: h?.proformano,
              company: h?.company,
              address: h?.address,
              subagent: h?.subagent,
              client: h?.client,
            };
            // eslint-disable-next-line
          } else {
            // eslint-disable-next-line
            return;
          }
        })
        .filter(a => a);

      setTotal(totalVal);
      setDiscount(discountVal);
      const netTaxable = totalVal - discountVal;
      let companyData;
      if (updatedHirechartListArray && updatedHirechartListArray.length) {
        companyData = companies?.find(
          cp => cp.name === updatedHirechartListArray[0]?.company
        );
      }
      const gstValue = Number(companyData?.gst) || 0;
      const gstAmount = netTaxable * (gstValue / 100);
      const grandTotalVal = netTaxable + gstAmount;
      setTaxable(netTaxable);
      setGrandTotal(grandTotalVal);

      setUpdatedHirechartList(updatedHirechartListArray);
    }
  }, [hirechartList, proformaList]);

  const columns: any = [
    {
      title: 'Date',
      field: 'date',
      defaultSort: 'desc',
      render: (rowData: any) => `${formatDate(rowData?.date)} `,
    },
    {
      title: 'V type',
      field: 'vtype',
      render: (rowData: any) => `${rowData?.vtype} `,
    },
    {
      title: 'Vehicle No',
      field: 'vehicleno',
      render: (rowData: any) => `${rowData?.vehno} `,
    },
    {
      title: 'Particular',
      field: 'particular',
      render: (rowData: any) => `${rowData?.particular} `,
    },
    {
      title: '8 Hrs / 100 KMs',
      field: 'kms',
      render: (rowData: any) => `${rowData?.kms} `,
    },
    {
      title: 'Ex Kms',
      field: 'xkms',
      render: (rowData: any) => `${rowData?.xkms} `,
    },
    {
      title: 'Rate',
      field: 'rate',
      render: (rowData: any) => `${rowData?.rate} `,
    },
    {
      title: 'Tot',
      field: 'tot1',
      render: (rowData: any) => `${rowData?.tot1} `,
    },
    {
      title: 'Ex HRs',
      field: 'xhrs',
      render: (rowData: any) => `${rowData?.xhrs} `,
    },
    {
      title: 'Wait Ch',
      field: 'wchr',
      render: (rowData: any) => `${rowData?.wchr} `,
    },
    {
      title: 'Tot',
      field: 'tot2',
      render: (rowData: any) => `${rowData?.tot2} `,
    },
    {
      title: 'Early',
      field: 'early',
      render: (rowData: any) => `${rowData?.early} `,
    },
    {
      title: 'Early Morning',
      field: 'early_morning',
      render: (rowData: any) => `${rowData?.early_morning} `,
    },
    {
      title: 'Late',
      field: 'late',
      render: (rowData: any) => `${rowData?.late} `,
    },
    {
      title: 'Nite',
      field: 'onite',
      render: (rowData: any) => `${rowData?.onite} `,
    },
    {
      title: 'Extra',
      field: 'toll',
      render: (rowData: any) => `${rowData?.toll} `,
    },
    {
      title: 'Total',
      field: 'tot3',
      render: (rowData: any) => `${rowData?.tot3} `,
    },
  ];

  return (
    <div className="content">
      <div className="content-inner">
        <Box style={{ padding: '10px', margin: '10px' }}>
          <Typography variant="h5">Proforma Details</Typography>
        </Box>

        {isLoading ? (
          <Box
            sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Card style={{ margin: '10px' }}>
            <Grid item xs={12} md={6} paddingTop={4} px={2}>
              <Typography variant="h6">
                Date:{' '}
                {updatedHirechartList
                  ? formatDate(updatedHirechartList[0]?.date)
                  : ''}
              </Typography>
              <Typography variant="h6">
                Inv No:{' '}
                {updatedHirechartList
                  ? updatedHirechartList[0]?.proformano
                  : ''}
              </Typography>
              <Typography variant="h6">
                Company:{' '}
                {updatedHirechartList ? updatedHirechartList[0]?.company : ''}
              </Typography>
              <Typography variant="h6">
                Address:{' '}
                {updatedHirechartList ? updatedHirechartList[0]?.address : ''}
              </Typography>
              <Typography variant="h6">
                Subagent:{' '}
                {updatedHirechartList ? updatedHirechartList[0]?.subagent : ''}
              </Typography>
              <Typography variant="h6">
                Ref:{' '}
                {updatedHirechartList ? updatedHirechartList[0]?.client : ''}
              </Typography>
            </Grid>

            <MaterialTable
              title=""
              columns={columns}
              data={updatedHirechartList || []}
              isLoading={isLoading}
              options={{
                draggable: false,
                paging: false,
                search: true,
                tableLayout: 'fixed',
                searchFieldVariant: 'outlined',
              }}
              style={{
                minHeight: 'calc(100vh - 180px)',
                overflowY: 'auto',
              }}
              components={{
                Container: props => <Paper elevation={0} {...props} />,
              }}
              actions={[
                {
                  icon: EditIcon,
                  tooltip: 'Edit Proforma',
                  onClick: (_, rowData) => handleEdit(rowData),
                },
                {
                  icon: DeleteIcon,
                  tooltip: 'Delete Proforma',
                  onClick: (_, rowData) => handleDelete(rowData),
                },
              ]}
            />
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              alignItems="flex-end"
              paddingRight={4}
            >
              <Grid item>
                <strong>Total (Rs.) </strong>
                {total}
              </Grid>
              <Grid item>
                <strong>Less </strong>
                {discount}
              </Grid>
              <Grid item>
                <strong>Net Taxable </strong>
                {taxable}
              </Grid>
              <Grid item>
                <strong>G. Total </strong>
                {grandTotal}
              </Grid>
            </Grid>
          </Card>
        )}
      </div>

      <DeleteDialog
        open={deleteDialogOpen}
        handleClose={handleCloseDeleteDialog}
        handleConfirmDelete={handleConfirmDelete}
      />
    </div>
  );
};

export default ProformaDetail;
